import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

import { CustomTextService } from 'src/app/core/services/custom-text/custom-text.service';

@Component({
  selector: 'odm-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlatformsComponent implements OnInit {
  @Input() account: any;
  constructor(public customText: CustomTextService) {}

  ngOnInit() {}
}
