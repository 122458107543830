import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import { slideRightAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideRightAnimation]
})
export class ToastsComponent implements OnInit {
  toasts$: Observable<any[]>;

  constructor(private store: Store<any>, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.toasts$ = this.store.pipe(select(fromStore.selectToasts));
  }

  hideToast(id) {
    this.store.dispatch(fromStore.hideToast({ id }));
  }

  trustHtml(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
