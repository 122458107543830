import { createAction, props } from '@ngrx/store';

export const clearPromotion = createAction('[Promotion] Clear Promotion');

export const loadPromotion = createAction('[Promotion] Load Promotion');

export const loadPromotionSuccess = createAction(
  '[Promotion] Load Promotion Success',
  props<{ promotion: any }>()
);

export const loadPromotionFailure = createAction(
  '[Promotion] Load Promotion Failure',
  props<{ error: any }>()
);
