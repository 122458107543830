import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/continue-subscription/continue-subscription.actions';

export interface State {
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  message: undefined,
  loaded: false,
  loading: false,
  error: undefined
};

const continueSubscriptionReducer = createReducer(
  initialState,
  on(fromActions.continueSubscription, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.continueSubscriptionSuccess, (state, { message }) => ({
    ...state,
    message,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.continueSubscriptionFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return continueSubscriptionReducer(state, action);
}
