import { Injectable } from '@angular/core';

import { fromEvent } from 'rxjs';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  scroll$ = fromEvent(document, 'scroll');

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
