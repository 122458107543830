import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectConfirmState = createSelector(
  fromFeature.selectCoreState,
  state => state.confirm
);

export const selectConfirmAccept = createSelector(
  selectConfirmState,
  state => state.accept
);

export const selectConfirmed = id =>
  createSelector(selectConfirmAccept, accept => accept[id]);
