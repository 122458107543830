import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'odm-progress-indeterminate',
  templateUrl: './progress-indeterminate.component.html',
  styleUrls: ['./progress-indeterminate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressIndeterminateComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
