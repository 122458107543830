import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromReducer from '../../reducers/channels/channels.reducer';
import * as fromRoot from '../../../../store';
import * as fromVideos from '../videos/videos.selectors';
import * as fromVideoUserData from '../video-user-data/video-user-data.selectors';

export const selectChannelsState = createSelector(
  fromFeature.selectCoreState,
  state => state.channels
);

const { selectEntities } = fromReducer.adapter.getSelectors();

export const selectChannelsEntities = createSelector(
  selectChannelsState,
  selectEntities
);

const selectChannelsIndex = createSelector(
  selectChannelsState,
  state => state.index
);

const selectChannelsEntityLoading = createSelector(
  selectChannelsState,
  state => state.entityLoading
);

const selectChannelsEntityError = createSelector(
  selectChannelsState,
  state => state.entityError
);

export const selectChannelsLoaded = createSelector(
  selectChannelsState,
  state => state.loaded
);

export const selectChannelsLoading = createSelector(
  selectChannelsState,
  state => state.loading
);

export const selectChannelsError = createSelector(
  selectChannelsState,
  state => state.error
);

export const selectChannel = createSelector(
  selectChannelsEntities,
  (entities, props) => entities[props.id]
);

export const selectChannelLoaded = id =>
  createSelector(
    selectChannelsEntities,
    entities => !!entities[id]
  );

export const selectChannelLoading = createSelector(
  selectChannelsEntityLoading,
  (entityLoading, props) => entityLoading[props.id]
);

export const selectChannelError = createSelector(
  selectChannelsEntityError,
  (entityError, props) => entityError[props.id]
);

export const denormalizeChannel = (
  id: string,
  channelEntities,
  videoEntities,
  videoUserDataEntities
) => {
  const channel = channelEntities[id];
  return (
    channel && {
      ...channel,
      trailer: fromVideos.denormalizeVideo(
        channel.trailer,
        videoEntities,
        videoUserDataEntities
      ),
      videos: fromVideos.denormalizeVideos(
        channel.videos,
        videoEntities,
        videoUserDataEntities
      )
    }
  );
};

export const denormalizeChannels = (
  ids: string[],
  channelEntities,
  videoEntities,
  videoUserDataEntities
) =>
  ids &&
  ids.map(id =>
    denormalizeChannel(
      id,
      channelEntities,
      videoEntities,
      videoUserDataEntities
    )
  );

export const selectChannels = createSelector(
  selectChannelsIndex,
  selectChannelsEntities,
  fromVideos.selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeChannels
);

export const selectActiveChannel = createSelector(
  fromRoot.selectChannelId,
  selectChannelsEntities,
  fromVideos.selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeChannel
);

export const selectRelatedChannel = createSelector(
  fromRoot.selectRelatedChannelId,
  selectChannelsEntities,
  fromVideos.selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeChannel
);
