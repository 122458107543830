import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, filter } from 'rxjs/operators';

import * as fromActions from '../../actions/video-user-data/video-user-data.actions';
import * as fromVideos from '../../actions/videos/videos.actions';
import { VideoUserDataService } from 'src/app/api/services';

@Injectable()
export class VideoUserDataEffects {
  saveVideoUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveVideoUserData),
      mergeMap(({ data, tag, video }) =>
        this.videoUserDataService.save(data).pipe(
          map(resp =>
            fromActions.saveVideoUserDataSuccess({
              data: resp['VideoUserData'],
              tag,
              video
            })
          ),
          catchError(error =>
            of(fromActions.saveVideoUserDataFailure({ id: data.id, error }))
          )
        )
      )
    )
  );

  saveVideoUserDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveVideoUserDataSuccess),
      map(({ data }) => fromActions.normalizeVideoUserData({ data: [data] }))
    )
  );

  saveFavoriteVideoUserDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveVideoUserDataSuccess),
      filter(({ tag }) => tag === 'Favorite'),
      map(({ data, video }) => {
        const { favorite } = data;
        return fromVideos.favoriteVideoSuccess({ video, favorite });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private videoUserDataService: VideoUserDataService
  ) {}
}
