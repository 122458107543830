import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/subscribe/subscribe.actions';

export interface State {
  email: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const email = localStorage.getItem('subscription-email');

export const initialState: State = {
  email,
  loaded: false,
  loading: false,
  error: undefined
};

const subscribeReducer = createReducer(
  initialState,
  on(fromActions.subscribe, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.subscribeSuccess, (state, { email }) => {
    localStorage.setItem('subscription-email', email);
    return {
      ...state,
      email,
      loaded: true,
      loading: false,
      error: undefined
    };
  }),
  on(fromActions.subscribeFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return subscribeReducer(state, action);
}
