import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/subscription-plans/subscription-plans.actions';

export interface State {
  plans: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  plans: [],
  loaded: false,
  loading: false,
  error: undefined
};

const subscriptionPlansReducer = createReducer(
  initialState,
  on(fromActions.loadSubscriptionPlans, state => ({
    ...state,
    plans: [],
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.loadSubscriptionPlansSuccess, (state, { plans }) => {
    const normalized = plans.map(plan => {
      const primary = plan['SubscriptionPlan'] || plan;
      const label =
        primary['display_price'] +
        (primary['trial_period_days'] > 0
          ? ` (${primary['trial_period_days']} Day Free Trial)`
          : '');
      return { ...primary, label };
    });
    return {
      ...state,
      plans: normalized,
      loaded: true,
      loading: false,
      error: undefined
    };
  }),
  on(fromActions.loadSubscriptionPlansFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return subscriptionPlansReducer(state, action);
}
