import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromReducer from '../../reducers/toasts/toasts.reducer';

export const selectToastsState = createSelector(
  fromFeature.selectCoreState,
  state => state.toasts
);

const { selectAll } = fromReducer.adapter.getSelectors();

export const selectToasts = createSelector(
  selectToastsState,
  selectAll
);
