import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideoUserDataService {
  constructor(private http: HttpClient) {}

  save(body: any) {
    return this.http.put<any>(
      `${environment.apiUrl}/api/2.0/video_user_data/edit/${body.id}.json`,
      body
    );
  }
}
