import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  event(action: string, data: any = null) {
    this.analytics('event', action, data);
  }

  pageview(url: string) {
    if (window['GA_MEASUREMENT_ID']) {
      this.analytics('config', window['GA_MEASUREMENT_ID'], { page_path: url });
    }
  }

  private get analytics() {
    return window['gtag'] || this.void;
  }

  private void(...params) {}
}
