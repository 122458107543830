import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/cancel-subscription/cancel-subscription.actions';

export interface State {
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  message: undefined,
  loaded: false,
  loading: false,
  error: undefined
};

const cancelSubscriptionReducer = createReducer(
  initialState,
  on(fromActions.cancelSubscription, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.cancelSubscriptionSuccess, (state, { message }) => ({
    ...state,
    message,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.cancelSubscriptionFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  })),
  on(fromActions.resetCancelSubscription, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return cancelSubscriptionReducer(state, action);
}
