import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class ConfirmModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  constructor(private store: Store<any>) {}

  ngOnInit() {}

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  confirm() {
    this.store.dispatch(fromStore.confirmAccept({ id: this.options.id }));
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }
}
