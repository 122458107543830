import { createAction, props } from '@ngrx/store';

export const checkSubscriptionEligibility = createAction(
  '[Subscription Eligibility] Check Subscription Eligibility',
  props<{ email: string; couponId: string; planId: string }>()
);

export const checkSubscriptionEligibilitySuccess = createAction(
  '[Subscription Eligibility] Check Subscription Eligibility Success',
  props<{ eligibility: any }>()
);

export const checkSubscriptionEligibilityFailure = createAction(
  '[Subscription Eligibility] Check Subscription Eligibility Failure',
  props<{ message: string }>()
);
