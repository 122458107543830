import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/pages/pages.actions';
import * as fromChannels from '../../../../../core/store/actions/channels/channels.actions';
import * as fromVideos from '../../../../../core/store/actions/videos/videos.actions';
import { PagesService } from '../../../../../api/services';

@Injectable()
export class PagesEffects {
  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPage),
      mergeMap(({ tag }) =>
        this.pagesService.view(tag).pipe(
          map(page => fromActions.loadPageSuccess({ tag, page })),
          catchError(error => of(fromActions.loadPageFailure({ tag, error })))
        )
      )
    )
  );

  loadPageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPageSuccess),
      map(({ tag, page }) => fromActions.normalizePage({ tag, page }))
    )
  );

  normalizePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.normalizePage),
      mergeMap(({ page }) => {
        const channels = [
          page['FeaturedChannel'],
          page['HeroChannel'],
          page['PreviewContentChannel']
        ].filter(channel => channel['id']);
        const videos = [page['HeroVideo'], page['HeroTrailerVideo']].filter(
          video => video['id']
        );
        return of(
          fromChannels.normalizeChannels({ channels }),
          fromVideos.normalizeVideos({ videos })
        );
      })
    )
  );

  constructor(private actions$: Actions, private pagesService: PagesService) {}
}
