import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'odm-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent implements OnInit {
  @Input() authenticated: boolean;
  @Input() account: any;
  @Input() content: any;
  constructor() {}

  ngOnInit() {

  }

  get heroClass() {
    let heroClass = [''];

    if(this.content.introduction_layout) {
      if(!this.authenticated) {
        heroClass.push(this.content.introduction_layout);
      } else {
        // @TODO remove after migration succeeds at making everyone left that is center
        this.content.introduction_layout = (this.content.introduction_layout === "center") ? 'left' : this.content.introduction_layout;
        heroClass.push(this.content.introduction_layout);
      }
    }

    if(
      this.content.introduction_background_format === 'image' ||
      this.content.introduction_background_format === 'video'
    ) {
      heroClass.push('has-overlay');
    }

    if(this.content.introduction_background_format === 'video') {
      heroClass.push('has-video-thumbnail');
    }

    if(
      this.content.featured_section === 'channel' ||
      this.content.featured_section === 'video'
    ) {
      heroClass.push('has-video-thumbnail');
    }

    return heroClass;
  }

  get heroStyle() {
    switch (this.content.introduction_background_format) {
      case 'image':

        return { 'background-image': `url(${this.backgroundImageUrl})` };
      case 'accent_color':
        return { 'background-color': 'var(--theme-color)' };
      case 'none':
        return { 'background': 'linear-gradient(180deg, #1D1F24 0%, #424B5A 100%)' };
      default:
        return { 'background': 'linear-gradient(180deg, #1D1F24 0%, #424B5A 100%)' };
    }
  }

   get buttonPrimaryClass(): string {
    return this.content['introduction_background_format'] === 'accent_color' ? 'btn-outline-light' : 'btn-outline-secondary';
  }

  get buttonSecondaryClass(): string {
    return this.content['introduction_background_format'] === 'accent_color' ? 'btn-outline-light' : 'btn-outline-secondary';
  }

  get actionsType(): string {
    if (!this.authenticated) return 'marketing';
    return this.content['featured_section'];
  }

  get backgroundFormat(): string {
    if(this.authenticated) {
      return this.content.background_format;
    } else {
      return this.content.introduction_background_format;
    }
  }

  get backgroundImageUrl() {
    if(this.authenticated) {
      return this.content.home_page_background_asset.url;
    } else {
      return this.content.introduction_background_image.url
    }

  }

  getActionsVisibility(placement) {
    if(placement === 'jumbotron-body') {
      return (!this.authenticated && this.content.introduction_featured_trailer_id);
    }
    if(placement === 'jumbotron-aside') {
      if(!this.authenticated ) {
        return false;
      } else{
        return true;
      }
    }
  }


}
