import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

import { AnchorService } from '../../core/services/anchor/anchor.service'

@Directive({
  selector: '[odmAnchor]'
})
export class AnchorDirective implements AfterViewInit {
  @Input() odmAnchor: string;
  constructor(private el: ElementRef, private anchorService: AnchorService) {}

  ngAfterViewInit() {
    this.anchorService.addAnchor(this.odmAnchor, this.el);
  }
}
