import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/change-subscription-payment/change-subscription-payment.actions';

export interface State {
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  message: undefined,
  loaded: false,
  loading: false,
  error: undefined
};

const changePaymentReducer = createReducer(
  initialState,
  on(fromActions.changeSubscriptionPayment, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.changeSubscriptionPaymentSuccess, (state, { message }) => ({
    ...state,
    message,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.changeSubscriptionPaymentFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  })),
  on(fromActions.resetChangeSubscriptionPayment, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return changePaymentReducer(state, action);
}
