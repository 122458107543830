import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromModals from '../../modals';
import { Modal } from '../../modals/modal.interface';
import { KeyboardService } from '../../services/keyboard/keyboard.service';

@Component({
  selector: 'odm-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalsComponent implements OnInit {
  @ViewChild('host', { read: ViewContainerRef, static: true })
  host: ViewContainerRef;
  ids = [];

  constructor(
    private store: Store<any>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private keyboard: KeyboardService
  ) {}

  ngOnInit() {
    const modals$ = this.store.pipe(select(fromStore.selectModals));
    modals$.subscribe((modals) => {
      if (this.ids.length < modals.length) {
        const add = modals.find((modal) => !this.ids.includes(modal.id));
        this.ids = [...this.ids, add.id];
        this.loadComponent(add);
      } else if (this.ids.length > modals.length) {
        const ids = modals.map((modal) => modal.id);
        const id = this.ids.find((id) => !ids.includes(id));
        const index = this.ids.indexOf(id);
        this.ids = this.ids.filter((i) => i !== id);
        this.removeComponent(index);
      }
      this.changeDetectorRef.markForCheck();
    });

    const modalsOpen$ = this.store.pipe(select(fromStore.selectModalsOpen));
    modalsOpen$.subscribe((open) => {
      if (open) {
        this.renderer.addClass(document.body, 'modal-open');
      } else {
        this.renderer.removeClass(document.body, 'modal-open');
      }
    });

    this.keyboard.esc$.subscribe(() => {
      const id = this.ids[this.ids.length - 1];
      if (id) {
        this.store.dispatch(fromStore.closeModal({ id }));
      }
    });
  }

  loadComponent(modal) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      fromModals.config[modal.key]
    );
    const componentRef = this.host.createComponent(componentFactory);
    const modalComponent = componentRef.instance as Modal;
    modalComponent.id = modal.id;
    modalComponent.options = modal.options;
  }

  removeComponent(index) {
    this.host.remove(index);
  }
}
