import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Renderer2,
  Input
} from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromCore from 'src/app/core/store';

@Component({
  selector: 'odm-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoItemComponent implements OnInit {
  @Input() video: any;
  @Input() activeId: string;
  @Input() channelId: string;
  favoriteLoading = false;
  tileHovered = false;

  constructor(
    private renderer: Renderer2,
    private store: Store<any>
  ) {}

  ngOnInit() {}

  toggleHover() {
    this.tileHovered = !this.tileHovered;
  }

  favorite(favorite) {
    this.favoriteLoading = true;
    this.store.dispatch(
      fromCore.favoriteVideo({ video: this.video, favorite })
    );
  }

  get link(): any[] {
    const params = [this.video['id']];
    if (this.channelId) {
      params.push(this.channelId);
    }
    const outlets = { video: params };
    return ['', { outlets }];
  }
}
