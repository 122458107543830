import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/subscriber-content/subscriber-content.actions';
import * as fromChannels from '../../../../core/store/actions/channels/channels.actions';
import * as fromVideos from '../../../../core/store/actions/videos/videos.actions';
import { SubscriberContentsService } from '../../../../api/services';

@Injectable()
export class SubscriberContentEffects {
  loadSubscriberContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSubscriberContent),
      mergeMap(() =>
        this.subscriberContentsService.view().pipe(
          map(content => fromActions.loadSubscriberContentSuccess({ content })),
          catchError(error => of(fromActions.loadSubscriberContentFailure({ error })))
        )
      )
    )
  );

  loadSubscriberContentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSubscriberContentSuccess),
      // map(({ tag, page }) => fromActions.normalizePage({ tag, page })),
      mergeMap(({ content }) => {
        const channels = [
          content['featured_channel'],
          content['intro_channel'],
        ].filter(channel => channel && channel['id']);
        const videos = [
          ...content['continue_videos'],
          ...content['new_videos'],
        ].filter(video => video && video['id']);
        return of(
          fromChannels.normalizeChannels({ channels }),
          fromVideos.normalizeVideos({ videos })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private subscriberContentsService: SubscriberContentsService
  ) {}
}
