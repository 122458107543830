import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  constructor(private http: HttpClient) {}

  viewPlans() {
    return this.http.get<any[]>(`${environment.apiUrl2}/subscription-plans.json?platform_type=Web`);
  }

  countCoupons() {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/coupon_codes/count.json`
    );
  }

  checkCoupon(code: string, planId: string) {
    const body = { coupon_code: code, subscription_plan_id: planId };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/coupon_codes/check_code.json`,
      body
    );
  }

  checkEligibility(email: string, couponId: string, planId: string) {
    const body = {
      email,
      coupon_code_id: couponId,
      subscription_plan_id: planId,
    };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/check_new_subscriber.json`,
      body
    );
  }

  subscribe(body: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/subscribe_through_web.json`,
      body
    );
  }

  resendActivationEmail(email: string) {
    const body = { email };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/resend_activation_email.json`,
      body
    );
  }

  activate(email: string, hash: string) {
    const body = { email, hash };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/activate_web_subscription.json`,
      body
    );
  }

  cancel() {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/cancel_subscription.json`
    );
  }

  continue() {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/continue_subscription.json`
    );
  }

  changePlan(body: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/change_subscription_plan.json`,
      body
    );
  }

  changePayment(body: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/edit_stripe_token.json`,
      body
    );
  }

  deletePending(email: string) {
    const body = { email };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/subscriptions/delete_pending.json`,
      body
    );
  }

  verifyEmail(email:string) {
    const body = { email };
    return this.http.post<any>(
      `${environment.apiUrl2}/subscriptions/verify-email.json`,
      body
    );
  }
}
