import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromActions from '../../actions/channels/channels.actions';
import { unwrap, pluckIds } from '../../../../util/code.util';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  loaded: boolean;
  loading: boolean;
  error: any;
  index: string[];
  entityLoading: { [id: string]: boolean };
  entityError: { [id: string]: any };
}

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: undefined,
  index: [],
  entityLoading: {},
  entityError: {}
});

const channelsReducer = createReducer(
  initialState,
  on(fromActions.normalizeChannels, (state, { channels }) => {
    const normalized = channels.map(channel => ({
      ...channel,
      trailer: unwrap(channel, 'trailer_video', 'id'),
      videos: pluckIds(channel, 'videos')
    }));
    return adapter.upsertMany(normalized, state);
  }),
  on(fromActions.loadChannels, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.loadChannelsSuccess, (state, { channels }) => {
    const index = channels.map(channel => channel.id);
    return {
      ...state,
      index,
      loaded: true,
      loading: false,
      error: undefined
    };
  }),
  on(fromActions.loadChannelsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  })),
  on(fromActions.loadChannel, (state, { id }) => {
    const entityLoading = {
      ...state.entityLoading,
      [id]: true
    };
    const entityError = {
      ...state.entityError,
      [id]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadChannelSuccess, (state, { channel }) => {
    const { id } = channel;
    const entityLoading = {
      ...state.entityLoading,
      [id]: false
    };
    const entityError = {
      ...state.entityError,
      [id]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadChannelFailure, (state, { id, error }) => {
    const entityLoading = {
      ...state.entityLoading,
      [id]: false
    };
    const entityError = {
      ...state.entityError,
      [id]: error
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return channelsReducer(state, action);
}
