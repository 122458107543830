import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { CustomTextService } from 'src/app/core/services/custom-text/custom-text.service';

@Component({
  selector: 'odm-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementComponent implements OnInit {
  @Input() content: any;
  @Output() viewPlatforms = new EventEmitter();
  constructor(public customText: CustomTextService) {}

  ngOnInit() {}
}
