import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/continue-subscription/continue-subscription.actions';
import * as fromCore from '../../../../../core/store';
import { SubscriptionsService } from '../../../../../api/services';
import { unwrap } from 'src/app/util/code.util';

@Injectable()
export class ContinueSubscriptionEffects {
  continueSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.continueSubscription),
      mergeMap(() =>
        this.subscriptionsService.continue().pipe(
          map(({ message }) =>
            fromActions.continueSubscriptionSuccess({ message })
          ),
          catchError(error =>
            of(fromActions.continueSubscriptionFailure({ error }))
          )
        )
      )
    )
  );

  continueSubscriptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.continueSubscriptionSuccess),
      mergeMap(({ message }) => {
        const toast = {
          title: 'Subscription Renewed',
          message,
          icon: 'assets/icons/material-design-icons/check_circle/sharp.svg'
        };
        return of(fromCore.loadUser(), fromCore.showToast({ toast }));
      })
    )
  );
  continueSubscriptionError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.continueSubscriptionFailure),
      map(({ error }) => {
        const toast = {
          title: 'Something Went Wrong',
          message:
            unwrap(error, 'error', 'message') || 'Error Renewing Subscription',
          icon: 'assets/icons/material-design-icons/error/sharp.svg'
        };
        return fromCore.showToast({ toast });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
