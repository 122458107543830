import { ChannelComponent } from './channel/channel.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VideoComponent } from './video/video.component';

export const components = [
  ChannelComponent,
  ComingSoonComponent,
  PageNotFoundComponent,
  VideoComponent,
];

export * from './channel/channel.component';
export * from './coming-soon/coming-soon.component';
export * from './page-not-found/page-not-found.component';
export * from './video/video.component';
