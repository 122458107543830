import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/device/device.actions';

export interface State {
  authenticated: boolean;
  authLoaded: boolean;
  authLoading: boolean;
  authError: any;
  email: string;
  token: string;
  method: string;
  type: string;
  tokenLoaded: boolean;
  tokenLoading: boolean;
  tokenError: any;
  authEmailLoaded: boolean;
  authEmailLoading: boolean;
  authEmailError: any;
}

const token = localStorage.getItem('device-token');

export const initialState: State = {
  authenticated: false,
  authLoaded: false,
  authLoading: false,
  authError: undefined,
  email: undefined,
  token,
  method: undefined,
  type: undefined,
  tokenLoaded: false,
  tokenLoading: false,
  tokenError: undefined,
  authEmailLoaded: false,
  authEmailLoading: false,
  authEmailError: undefined
};

const deviceReducer = createReducer(
  initialState,
  on(fromActions.saveDevice, (state, { email }) => ({
    ...state,
    email,
    tokenLoaded: false,
    tokenLoading: true,
    tokenError: undefined
  })),
  on(fromActions.saveDeviceSuccess, (state, { token, method }) => {
    localStorage.setItem('device-token', token);
    return {
      ...state,
      token,
      method,
      tokenLoaded: true,
      tokenLoading: false,
      tokenError: undefined
    };
  }),
  on(fromActions.saveDeviceFailure, (state, { error }) => ({
    ...state,
    tokenError: error,
    tokenLoaded: false,
    tokenLoading: false
  })),
  on(fromActions.authenticateDevice, state => ({
    ...state,
    authLoaded: false,
    authLoading: true,
    authError: undefined
  })),
  on(fromActions.authenticateDeviceSuccess, (state, { token }) => {
    if (token) {
      localStorage.setItem('device-token', token);
    }
    return {
      ...state,
      token: token || state.token,
      authenticated: true,
      authLoaded: true,
      authLoading: false,
      authError: undefined
    };
  }),
  on(fromActions.authenticateDeviceFailure, (state, { error }) => ({
    ...state,
    authError: error,
    authLoaded: false,
    authLoading: false
  })),
  on(fromActions.resendDeviceAuthEmail, state => ({
    ...state,
    authEmailLoaded: false,
    authEmailLoading: true,
    authEmailError: undefined
  })),
  on(fromActions.resendDeviceAuthEmailSuccess, state => ({
    ...state,
    authEmailLoaded: true,
    authEmailLoading: false,
    authEmailError: undefined
  })),
  on(fromActions.resendDeviceAuthEmailFailure, (state, { error }) => ({
    ...state,
    authEmailError: error,
    authEmailLoaded: false,
    authEmailLoading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return deviceReducer(state, action);
}
