import { createAction, props } from '@ngrx/store';

export const normalizeChannels = createAction(
  '[Channels] Normalize Channels',
  props<{ channels: any[] }>()
);

export const loadChannels = createAction('[Channels] Load Channels');

export const loadChannelsSuccess = createAction(
  '[Channels] Load Channels Success',
  props<{ channels: any[] }>()
);

export const loadChannelsFailure = createAction(
  '[Channels] Load Channels Failure',
  props<{ error: any }>()
);

export const loadChannel = createAction(
  '[Channels] Load Channel',
  props<{ id: string }>()
);

export const loadChannelSuccess = createAction(
  '[Channels] Load Channel Success',
  props<{ channel: any }>()
);

export const loadChannelFailure = createAction(
  '[Channels] Load Channel Failure',
  props<{ id: string; error: any }>()
);
