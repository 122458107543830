import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root',
})
export class ChannelResourcesGuard implements CanActivate {
  constructor(private store: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const source = 'channel';
    const id = route.params.channelId;
    return this.store.pipe(
      select(fromStore.selectResourcesLoaded(source, id)),
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(fromStore.loadResources({ source, id }));
        }
      }),
      map(() => true)
    );
  }
}
