import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromActions from '../../actions/activate-subscription/activate-subscription.actions';
import * as fromDevice from '../../../../../core/store/actions/device/device.actions';
import * as fromModals from '../../../../../core/store/actions/modals/modals.actions';
import * as fromToasts from '../../../../../core/store/actions/toasts/toasts.actions';
import { SubscriptionsService } from '../../../../../api/services';
import { selectDeviceAuthenticated } from 'src/app/core/store';
import { unwrap } from 'src/app/util/code.util';

@Injectable()
export class ActivateSubscriptionEffects {
  activateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateSubscription),
      mergeMap(({ email, code }) =>
        this.subscriptionsService.activate(email, code).pipe(
          map((resp) =>
            fromActions.activateSubscriptionSuccess({
              token: resp['Device']['user_token'],
            })
          ),
          catchError((error) =>
            of(fromActions.activateSubscriptionFailure({ error }))
          )
        )
      )
    )
  );

  activateSubscriptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateSubscriptionSuccess),
      map(({ token }) => fromDevice.authenticateDeviceSuccess({ token }))
    )
  );

  activateSubscriptionFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateSubscriptionFailure),
      withLatestFrom(this.store.pipe(select(selectDeviceAuthenticated))),
      mergeMap(([{ error }, authenticated]) => {
        if (authenticated) {
          const toast = {
            title: 'All Set',
            message: 'You are already signed in and activated.',
            icon: 'assets/icons/material-design-icons/check_circle/sharp.svg',
          };
          this.router.navigate(['user', 'account']);
          return of(fromToasts.showToast({ toast }));
        } else {
          const message = unwrap(error, 'error', 'message');
          if (message && message.startsWith('Your subscription could not be activated')) {
            return EMPTY;
          }
          const toast = {
            title: 'Please Sign In',
            message:
              'This activation link was already used. Please proceed to sign in.',
            icon: 'assets/icons/material-design-icons/error/sharp.svg',
          };
          return of(
            fromToasts.showToast({ toast }),
            fromModals.openModal({ key: 'auth' })
          );
        }
      })
    )
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<any>,
    private subscriptionsService: SubscriptionsService
  ) {}
}
