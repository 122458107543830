import { createAction, props } from '@ngrx/store';

export const activateSubscription = createAction(
  '[Activate Subscription] Activate Subscription',
  props<{ email: string; code: string }>()
);

export const activateSubscriptionSuccess = createAction(
  '[Activate Subscription] Activate Subscription Success',
  props<{ token: string }>()
);

export const activateSubscriptionFailure = createAction(
  '[Activate Subscription] Activate Subscription Failure',
  props<{ error: any }>()
);
