import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/delete-pending-subscription/delete-pending-subscription.actions';

export interface State {
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  message: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

const deletePendingSubscriptionReducer = createReducer(
  initialState,
  on(fromActions.deletePendingSubscription, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined,
  })),
  on(fromActions.deletePendingSubscriptionSuccess, (state, { message }) => ({
    ...state,
    message,
    loaded: true,
    loading: false,
    error: undefined,
  })),
  on(fromActions.deletePendingSubscriptionFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return deletePendingSubscriptionReducer(state, action);
}
