import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import * as fromComponents from './components';
import * as fromDirectives from './directives';
import * as fromPipes from './pipes';

@NgModule({
  declarations: [
    ...fromComponents.components,
    ...fromDirectives.directives,
    ...fromPipes.pipes
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ...fromComponents.components,
    ...fromDirectives.directives,
    ...fromPipes.pipes
  ]
})
export class SharedModule {}
