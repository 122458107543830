import { createAction, props } from '@ngrx/store';

export const cancelSubscription = createAction(
  '[Cancel Subscription] Cancel Subscription'
);

export const cancelSubscriptionSuccess = createAction(
  '[Cancel Subscription] Cancel Subscription Success',
  props<{ message: string }>()
);

export const cancelSubscriptionFailure = createAction(
  '[Cancel Subscription] Cancel Subscription Failure',
  props<{ error: any }>()
);

export const resetCancelSubscription = createAction(
  '[Cancel Subscription] Reset Cancel Subscription'
);
