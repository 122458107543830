import { createAction, props } from '@ngrx/store';

export const verifyEmail = createAction(
  '[Verify Email] Verify Email',
  props<{ code: string }>()
);

export const verifyEmailSuccess = createAction(
  '[Verify Email] Verify Email Success'
);

export const verifyEmailFailure = createAction(
  '[Verify Email] Verify Email Failure',
  props<{ error: any }>()
);
