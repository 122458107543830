import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/subscription-email/subscription-email.actions';
import { SubscriptionsService } from '../../../../api/services';

@Injectable()
export class SubscriptionEmailEffects {
  resendSubscriptionActivationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendSubscriptionActivationEmail),
      mergeMap(({ email }) =>
        this.subscriptionsService.resendActivationEmail(email).pipe(
          map(() => fromActions.resendSubscriptionActivationEmailSuccess()),
          catchError(error =>
            of(fromActions.resendSubscriptionActivationEmailFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
