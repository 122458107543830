import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/verify-mobile/verify-mobile.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: undefined
};

const verifyMobilelReducer = createReducer(
  initialState,
  on(fromActions.verifyMobile, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.verifyMobileSuccess, state => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.verifyMobileFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return verifyMobilelReducer(state, action);
}
