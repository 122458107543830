import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/text-comm/text-comm.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: undefined
};

const textCommReducer = createReducer(
  initialState,
  on(fromActions.sendAppLink, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.sendAppLinkSuccess, state => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.sendAppLinkFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return textCommReducer(state, action);
}
