import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectActivateSubscriptionState = createSelector(
  fromFeature.selectUserState,
  state => state.activateSubscription
);

export const selectActivateSubscriptionLoaded = createSelector(
  selectActivateSubscriptionState,
  state => state.loaded
);

export const selectActivateSubscriptionLoading = createSelector(
  selectActivateSubscriptionState,
  state => state.loading
);

export const selectActivateSubscriptionError = createSelector(
  selectActivateSubscriptionState,
  state => state.error
);
