import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectSubscriptionEligibilityState = createSelector(
  fromFeature.selectUserState,
  state => state.subscriptionEligibility
);

export const selectSubscriptionEligibilityResult = createSelector(
  selectSubscriptionEligibilityState,
  state => state.result
);

export const selectSubscriptionEligibilityMessage = createSelector(
  selectSubscriptionEligibilityState,
  state => state.message
);

export const selectSubscriptionEligibilityAllowCoupon = createSelector(
  selectSubscriptionEligibilityState,
  state => state.allowCoupon
);

export const selectSubscriptionEligibilityLoading = createSelector(
  selectSubscriptionEligibilityState,
  state => state.loading
);
