import { Injectable } from '@angular/core';

import { ScriptService } from 'ngx-script-loader';

import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

declare const Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  stripe: any;
  elements: any;
  loaded$ = new BehaviorSubject(false);
  private _card: any;

  constructor(private scriptService: ScriptService) {}

  init() {
    this.scriptService.loadScript('https://js.stripe.com/v3').subscribe(() => {
      this.stripe = Stripe(environment.stripeKey);
      this.elements = this.stripe.elements();
      this.loaded$.next(true);
    });
  }

  get card() {
    if (!this._card) {
      // stripe card config: https://stripe.com/docs/stripe-js
      this._card = this.elements.create('card', {
        style: {
          base: {
            color: '#fff',
            '::placeholder': {
              color: '#6c757d',
            },
            // fontSize: '1.125rem',
            // fontWeight: '400',
            // lineHeight: '1.2'
          }
        }
      });
    }
    return this._card;
  }

  createToken(extras?: any) {
    return this.stripe.createToken(this._card, extras);
  }

  unmount() {
    this._card.unmount();
  }
}
