import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import * as fromUser from '../../../modules/user/store';
import { fadeInAnimation } from 'src/app/util/animation.util';
import * as moment from 'moment';

@Component({
  selector: 'odm-cancel-subscription-modal',
  templateUrl: './cancel-subscription-modal.component.html',
  styleUrls: ['./cancel-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class CancelSubscriptionModalComponent implements Modal, OnInit {
  id: number;
  message$: Observable<string>;
  loaded$: Observable<boolean>;
  loading$: Observable<boolean>;
  error$: Observable<any>;
  locked$: Observable<boolean>;
  user$: Observable<any>;
  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.store.dispatch(fromUser.resetCancelSubscription());

    this.message$ = this.store.pipe(
      select(fromUser.selectCancelSubscriptionMessage)
    );
    this.loaded$ = this.store.pipe(
      select(fromUser.selectCancelSubscriptionLoaded)
    );
    this.loading$ = this.store.pipe(
      select(fromUser.selectCancelSubscriptionLoading)
    );
    this.error$ = this.store.pipe(
      select(fromUser.selectCancelSubscriptionError)
    );
    this.locked$ = this.store.pipe(select(fromStore.selectUserLocked));
    this.user$ = this.store.pipe(select(fromStore.selectUser));
  }

  formatDate(date: string, format = 'LL'): string {
    return moment(date).format(format);
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  cancelSubscription() {
    this.store.dispatch(fromUser.cancelSubscription());
  }
}
