import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {
  constructor(private http: HttpClient) {}

  view(id: string) {
    return this.http.get<any>(`${environment.apiUrl2}/channels/view/${id}.json`);
  }

  list() {
    return this.http.get<any[]>(`${environment.apiUrl2}/channels.json`);
  }
}
