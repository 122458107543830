import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, finalize, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class HomeContentGuard implements CanActivate {
  constructor(private store: Store<any>) {}

  loadMarketingContent(): Observable<boolean> {
    let loading: boolean;
    return this.store.pipe(
      select(fromStore.selectMarketingContentLoaded),
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.loadMarketingContent());
          this.store.dispatch(fromStore.increaseLoadingCount());
          loading = true;
        }
      }),
      finalize(() => {
        if (loading) {
          this.store.dispatch(fromStore.decreaseLoadingCount());
        }
      }),
      filter(loaded => loaded)
    );
  }

  loadSubscriberContent(): Observable<boolean> {
    let loading: boolean;
    return this.store.pipe(
      select(fromStore.selectSubscriberContentLoaded),
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.loadSubscriberContent());
          this.store.dispatch(fromStore.increaseLoadingCount());
          loading = true;
        }
      }),
      finalize(() => {
        if (loading) {
          this.store.dispatch(fromStore.decreaseLoadingCount());
        }
      }),
      filter(loaded => loaded)
    );
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.selectUserAuthLoaded),
      filter(loaded => loaded),
      switchMap(() =>
        this.store.pipe(
          select(fromStore.selectUserAuthenticated),
          switchMap(authenticated =>
            authenticated ?
              this.loadSubscriberContent() :
              this.loadMarketingContent()
          )
        )
      )
    );
  }
}
