import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectSecurityState = createSelector(
  fromFeature.selectUserState,
  state => state.security
);

export const selectSecurityLoaded = createSelector(
  selectSecurityState,
  state => state.loaded
);

export const selectSecurityLoading = createSelector(
  selectSecurityState,
  state => state.loading
);

export const selectSecurityError = createSelector(
  selectSecurityState,
  state => state.error
);

export const selectSecurityMessage = createSelector(
  selectSecurityState,
  state => state.message
);

export const selectSecurityEmail = createSelector(
  selectSecurityState,
  state => state.email
);

export const selectSecurityVerificationHash = createSelector(
  selectSecurityState,
  state => state.verification_hash
);
