import { createAction, props } from '@ngrx/store';

export const loadSubscriberContent = createAction('[Subscriber Content] Load Subscriber Content');

export const loadSubscriberContentSuccess = createAction(
  '[Subscriber Content] Load Subscriber Content Success',
  props<{ content: any }>()
);

export const loadSubscriberContentFailure = createAction(
  '[Subscriber Content] Load Subscriber Content Failure',
  props<{ error: any }>()
);
