import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromRoot from '../../../../store';
import { resourceKey } from 'src/app/util/code.util';

export const selectResourcesState = createSelector(
  fromFeature.selectCoreState,
  (state) => state.resources
);

const selectResourcesEntities = createSelector(
  selectResourcesState,
  (state) => state.entities
);

const selectResourcesEntityLoading = createSelector(
  selectResourcesState,
  (state) => state.entityLoading
);

const selectResourcesEntityError = createSelector(
  selectResourcesState,
  (state) => state.entityError
);

export const selectResourcesLoaded = (source, id) => {
  const key = resourceKey(source, id);
  return createSelector(selectResourcesEntities, (entities) => !!entities[key]);
};

export const selectActiveChannelResources = createSelector(
  fromRoot.selectChannelId,
  selectResourcesEntities,
  (id, entities) => {
    const key = resourceKey('channel', id);
    return entities[key];
  }
);

export const selectActiveVideoResources = createSelector(
  fromRoot.selectVideoId,
  selectResourcesEntities,
  (id, entities) => {
    const key = resourceKey('video', id);
    return entities[key];
  }
);
