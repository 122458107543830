import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/confirm/confirm.actions';

export interface State {
  accept: { [id: string]: any };
}

export const initialState: State = {
  accept: {}
};

const confirmReducer = createReducer(
  initialState,
  on(fromActions.confirmAccept, (state, { id, data }) => ({
    ...state,
    accept: { ...state.accept, [id]: data || true }
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return confirmReducer(state, action);
}
