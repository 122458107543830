import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { unwrap } from 'src/app/util/code.util';
import { CustomTextService } from '../../../../core/services/custom-text/custom-text.service';

@Component({
  selector: 'odm-hero-actions',
  templateUrl: './hero-actions.component.html',
  styleUrls: ['./hero-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroActionsComponent {
  @Input() authenticated: boolean;
  @Input() content: any;
  @Input() visible: boolean;

  constructor(
    public customText: CustomTextService
  ) { }

  get buttonPrimaryClass(): string {
    return this.content['introduction_background_format'] === 'accent_color' ? 'btn-outline-light' : 'btn-primary';
  }

  get buttonSecondaryClass(): string {
    return this.content['introduction_background_format'] === 'accent_color' ? 'btn-outline-light' : 'btn-outline-secondary';
  }

  get headerLabel(): string {
    switch (this.content['featured_section']) {
      case 'channel':
        return `Featured ${this.customText.channelTerm}`;
      case 'video':
        return `Featured Video`
    }
  }

  get headerTitle(): string {
    switch (this.content['featured_section']) {
      case 'channel':
        return unwrap(this.content, 'intro_channel', 'title');
      case 'video':
        return unwrap(this.content, 'intro_video', 'title');
    }
  }

  get actionsType(): string {
    if (!this.authenticated) return 'marketing';
    return this.content['featured_section'];
  }

}
