import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromPages from './pages';
import * as fromComponents from './components';
import * as fromModals from './modals';
import * as fromGuards from './guards';
import * as fromServices from './services';
import { SharedModule } from '../shared/shared.module';
import { ApiModule } from '../api/api.module';
import { featureKey, reducers, effects } from './store';

const routes: Routes = [
  {
    path: ':channelId',
    canActivate: [fromGuards.ChannelGuard, fromGuards.ChannelResourcesGuard],
    component: fromPages.ChannelComponent,
    outlet: 'channel',
  },
  {
    path: ':videoId',
    canActivate: [fromGuards.VideoGuard, fromGuards.VideoResourcesGuard],
    component: fromPages.VideoComponent,
    outlet: 'video',
  },
  {
    path: ':videoId/:relatedChannelId',
    canActivate: [fromGuards.VideoGuard, fromGuards.VideoResourcesGuard, fromGuards.ChannelGuard],
    component: fromPages.VideoComponent,
    outlet: 'video',
  },
];

@NgModule({
  imports: [
    SharedModule,
    ApiModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature(effects),
  ],
  declarations: [
    ...fromPages.components,
    ...fromComponents.components,
    ...fromModals.components,
  ],
  entryComponents: [...fromModals.components],
  providers: [...fromGuards.guards, ...fromServices.services],
  exports: [...fromComponents.components],
})
export class CoreModule { }
