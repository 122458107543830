import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/promotion/promotion.actions';
import { PromotionsService } from '../../../../api/services';

@Injectable()
export class PromotionEffects {
  loadPromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPromotion),
      mergeMap(() =>
        this.promotionsService.view().pipe(
          map(promotion =>
            fromActions.loadPromotionSuccess({ promotion })
          ),
          catchError(error => of(fromActions.loadPromotionFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private promotionsService: PromotionsService
  ) {}
}
