import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../../store';
import * as fromStore from '../../store';
import { fadeAnimation } from 'src/app/util/animation.util';
import { unwrap } from 'src/app/util/code.util';

@Component({
  selector: 'odm-streaming-now',
  templateUrl: './streaming-now.component.html',
  styleUrls: ['./streaming-now.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class StreamingNowComponent implements OnInit {
  routeData$: Observable<any>;
  streamKey$: Observable<any>;
  closed = false;
  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.routeData$ = this.store.pipe(select(fromRoot.selectRouteData));
    this.streamKey$ = this.store.pipe(
      select(fromStore.selectLiveStreamKey)
    );

    const id$ = this.streamKey$.pipe(
      map(streamKey => unwrap(streamKey, 'LiveVideo', 'id')),
      distinctUntilChanged()
    );
    id$.subscribe(id => {
      this.closed = false;
    });
  }

  dismiss() {
    this.closed = true;
  }
}
