import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/activate-tv/activate-tv.actions';

export interface State {
  activated: boolean;
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  activated: false,
  message: undefined,
  loaded: false,
  loading: false,
  error: undefined
};

const activateTVReducer = createReducer(
  initialState,
  on(fromActions.activateTV, state => ({
    ...state,
    message: undefined,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.activateTVSuccess, (state, { activated, message }) => ({
    ...state,
    activated,
    message,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.activateTVFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return activateTVReducer(state, action);
}
