import { createAction, props } from '@ngrx/store';

export const changeSubscriptionPlan = createAction(
  '[Change Subscription Plan] Change Subscription Plan',
  props<{ plan: any }>()
);

export const changeSubscriptionPlanSuccess = createAction(
  '[Change Subscription Plan] Change Subscription Plan Success',
  props<{ message: string }>()
);

export const changeSubscriptionPlanFailure = createAction(
  '[Change Subscription Plan] Change Subscription Plan Failure',
  props<{ error: any }>()
);

export const resetChangeSubscriptionPlan = createAction(
  '[Change Subscription Plan] Reset Change Subscription Plan'
);
