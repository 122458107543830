import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectSubscriptionEmailState = createSelector(
  fromFeature.selectCoreState,
  state => state.subscriptionEmail
);

export const selectSubscriptionEmailLoaded = createSelector(
  selectSubscriptionEmailState,
  state => state.loaded
);

export const selectSubscriptionEmailLoading = createSelector(
  selectSubscriptionEmailState,
  state => state.loading
);

export const selectSubscriptionEmailError = createSelector(
  selectSubscriptionEmailState,
  state => state.error
);
