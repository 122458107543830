import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectDeviceState = createSelector(
  fromFeature.selectCoreState,
  state => state.device
);

export const selectDeviceAuthenticated = createSelector(
  selectDeviceState,
  state => state.authenticated
);

export const selectDeviceAuthLoaded = createSelector(
  selectDeviceState,
  state => state.authLoaded
);

export const selectDeviceAuthLoading = createSelector(
  selectDeviceState,
  state => state.authLoading
);

export const selectDeviceAuthError = createSelector(
  selectDeviceState,
  state => state.authError
);

export const selectDeviceEmail = createSelector(
  selectDeviceState,
  state => state.email
);

export const selectDeviceToken = createSelector(
  selectDeviceState,
  state => state.token
);

export const selectDeviceMethod = createSelector(
  selectDeviceState,
  state => state.method
);

export const selectDeviceType = createSelector(
  selectDeviceState,
  state => state.type
);

export const selectDeviceTokenLoaded = createSelector(
  selectDeviceState,
  state => state.tokenLoaded
);

export const selectDeviceTokenLoading = createSelector(
  selectDeviceState,
  state => state.tokenLoading
);

export const selectDeviceTokenError = createSelector(
  selectDeviceState,
  state => state.tokenError
);

export const selectDeviceAuthEmailLoaded = createSelector(
  selectDeviceState,
  state => state.authEmailLoaded
);

export const selectDeviceAuthEmailLoading = createSelector(
  selectDeviceState,
  state => state.authEmailLoading
);

export const selectDeviceAuthEmailError = createSelector(
  selectDeviceState,
  state => state.authEmailError
);
