import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as fromActions from '../../actions/resources/resources.actions';
import { ResourcesService } from '../../../../api/services';
import { resourceKey } from 'src/app/util/code.util';

@Injectable()
export class ResourcesEffects {
  loadResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadResources),
      mergeMap(({ source, id }) => {
        const key = resourceKey(source, id);
        return this.resourcesService.list(source, id).pipe(
          map((resources) =>
            fromActions.loadResourcesSuccess({ key, resources })
          ),
          catchError((error) =>
            of(fromActions.loadResourcesFailure({ key, error }))
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private resourcesService: ResourcesService
  ) {}
}
