import {
  Directive,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ElementRef
} from '@angular/core';

import { Subscription } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

import { ClickService } from '../../core/services/click/click.service';

@Directive({
  selector: '[odmClickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() odmClickOutside: EventEmitter<void> = new EventEmitter<void>();
  private subscription: Subscription;

  constructor(
    private element: ElementRef,
    private clickService: ClickService
  ) {}

  ngOnInit() {
    this.subscription = this.clickService.clickTarget$
      .pipe(
        skip(1),
        filter(
          (target: HTMLElement) => !this.element.nativeElement.contains(target)
        )
      )
      .subscribe(() => this.odmClickOutside.emit());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
