import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import { Observable, combineLatest } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromCore from '../../../core/store';
import { unwrap } from '../../../util/code.util';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<any>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return combineLatest(
      this.store.pipe(select(fromCore.selectAccount)),
      this.store.pipe(select(fromCore.selectDeviceToken))
    ).pipe(
      first(),
      mergeMap(([account, token]) => {
        let { headers } = req;
        const property = unwrap(account, 'property');
        if (property) {
          headers = headers.set('X-Account-Property', property);
        }
        if (token) {
          headers = headers.set('X-Device-User-Token', token);
        }
        if (headers) {
          const authReq = req.clone({ headers });
          return next.handle(authReq);
        }
        return next.handle(req);
      })
    );
  }
}
