import { createAction, props } from '@ngrx/store';

export const subscribe = createAction(
  '[Subscribe] Subscribe',
  props<{ subscription: any }>()
);

export const subscribeSuccess = createAction(
  '[Subscribe] Subscribe Success',
  props<{ email: any }>()
);

export const subscribeFailure = createAction(
  '[Subscribe] Subscribe Failure',
  props<{ error: any }>()
);
