import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectDeletePendingSubscriptionState = createSelector(
  fromFeature.selectCoreState,
  (state) => state.deletePendingSubscription
);

export const selectDeletePendingSubscriptionMessage = createSelector(
  selectDeletePendingSubscriptionState,
  (state) => state.message
);

export const selectDeletePendingSubscriptionLoaded = createSelector(
  selectDeletePendingSubscriptionState,
  (state) => state.loaded
);

export const selectDeletePendingSubscriptionLoading = createSelector(
  selectDeletePendingSubscriptionState,
  (state) => state.loading
);

export const selectDeletePendingSubscriptionError = createSelector(
  selectDeletePendingSubscriptionState,
  (state) => state.error
);
