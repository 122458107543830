import { createAction, props } from '@ngrx/store';

export const loadAccount = createAction('[Account] Load Account');

export const loadAccountSuccess = createAction(
  '[Account] Load Account Success',
  props<{ account: any }>()
);

export const loadAccountFailure = createAction(
  '[Account] Load Account Failure',
  props<{ error: any }>()
);
