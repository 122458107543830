import { createAction, props } from '@ngrx/store';

export const loadResources = createAction(
  '[Resources] Load Resources',
  props<{ source: 'channel' | 'video'; id: string }>()
);

export const loadResourcesSuccess = createAction(
  '[Resources] Load Resources Success',
  props<{ key: string; resources: any[] }>()
);

export const loadResourcesFailure = createAction(
  '[Resources] Load Resources Failure',
  props<{ key: string; error: any }>()
);
