import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromRoot from '../../../store';

@Component({
  selector: 'odm-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComingSoonComponent implements OnInit {
  account$: Observable<any>;
  constructor(private store: Store<any>) { }

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromStore.selectAccount));

    const queryModal$ = this.store.pipe(
      select(fromRoot.selectQueryModal),
      filter((key) => !!key)
    );
    queryModal$.subscribe((key) => {
      this.store.dispatch(fromStore.openModal({ key }));
    });
  }
}
