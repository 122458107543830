import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

import { Store } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

declare const grecaptcha: any;

@Component({
  selector: 'odm-captcha-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrls: ['./captcha-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class CaptchaModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  @ViewChild('captcha', { static: false }) captcha: ElementRef;
  constructor(private store: Store<any>) { }

  ngOnInit() { }

  ngAfterViewInit() {
    grecaptcha.render(this.captcha.nativeElement, {
      sitekey: '6LcjivkZAAAAAHzEHBMmU_rRTFwsuTtLnp53Te3y',
      theme: 'dark',
      callback: (data) => {
        this.store.dispatch(fromStore.confirmAccept({ id: this.options.id, data }));
        this.close();
      }
    });
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }
}
