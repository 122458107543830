import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/subscription-plans/subscription-plans.actions';
import { SubscriptionsService } from '../../../../../api/services';

@Injectable()
export class SubscriptionPlansEffects {
  loadSubscriptionPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSubscriptionPlans),
      mergeMap(() =>
        this.subscriptionsService.viewPlans().pipe(
          map(plans =>
            fromActions.loadSubscriptionPlansSuccess({ plans })
          ),
          catchError(error =>
            of(fromActions.loadSubscriptionPlansFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) { }
}
