import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

// import intlTelInput from 'intl-tel-input';

// declare const $: any;

@Component({
  selector: 'odm-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class EditUserModalComponent implements Modal, OnInit {
  id: number;
  loaded$: Observable<boolean>;
  loading$: Observable<boolean>;
  message$: Observable<string>;
  error$: Observable<any>;
  userForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    mobile_number: '',
  });
  // @ViewChild('mobileNumber', { static: true }) mobileNumber: ElementRef;
  // iti: any;
  constructor(private store: Store<any>, private fb: FormBuilder) { }

  ngOnInit() {
    // this.iti = intlTelInput(this.mobileNumber.nativeElement, {});
    // this.onCountryChange();

    this.loaded$ = this.store.pipe(select(fromStore.selectUserEditLoaded));
    this.loading$ = this.store.pipe(select(fromStore.selectUserEditLoading));
    this.message$ = this.store.pipe(select(fromStore.selectUserEditMessage));
    this.error$ = this.store.pipe(select(fromStore.selectUserEditError));

    const user$ = this.store.pipe(select(fromStore.selectUser), take(1));
    user$.subscribe(user => {
      this.userForm.patchValue(user);
      // this.iti.setCountry('gb');
    });

    this.store.dispatch(fromStore.editUserReset());
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  onSubmit() {
    const user = this.userForm.value;
    this.store.dispatch(fromStore.editUser({ user }));
  }

  onCountryChange() {
  //   const data = this.iti.getSelectedCountryData();
  //   console.log(data);
  //   console.log(this.userForm);

  // //   this.parentForm.patchValue({ dialCode: data.dialCode });

  //   switch (data.iso2) {
  //     case 'us':
  //       $(this.mobileNumber.nativeElement).mask('(000) 000-0000');
  //       break;

  //     default:
  //       $(this.mobileNumber.nativeElement).unmask();
  //       break;
  //   }
  }
}
