import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-existing-subscription-modal',
  templateUrl: './existing-subscription-modal.component.html',
  styleUrls: ['./existing-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class ExistingSubscriptionModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  tokenLoaded$: Observable<boolean>;
  tokenLoading$: Observable<boolean>;
  tokenError$: Observable<any>;
  constructor(private store: Store<any>, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.tokenLoaded$ = this.store.pipe(
      select(fromStore.selectDeviceTokenLoaded)
    );
    this.tokenLoading$ = this.store.pipe(
      select(fromStore.selectDeviceTokenLoading)
    );
    this.tokenError$ = this.store.pipe(
      select(fromStore.selectDeviceTokenError)
    );
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  trustHtml(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  auth() {
    const { email } = this.options;
    this.store.dispatch(fromStore.saveDevice({ email }));
  }
}
