import { createAction, props } from '@ngrx/store';

export const sendAppLink = createAction(
  '[Text Comm] Send App Link',
  props<{ phone: string, token: string }>()
);

export const sendAppLinkSuccess = createAction(
  '[Text Comm] Send App Link Success'
);

export const sendAppLinkFailure = createAction(
  '[Text Comm] Send App Link Failure',
  props<{ error: any }>()
);
