import { Injectable } from '@angular/core';

import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup');
  esc$ = this.keyUp$.pipe(filter(event => event.key === 'Escape'));
}
