import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromActions from '../../actions/video-user-data/video-user-data.actions';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  entityLoading: { [id: string]: boolean };
  entityError: { [id: string]: any };
}

export const initialState: State = adapter.getInitialState({
  entityLoading: {},
  entityError: {}
});

const videoUserDataReducer = createReducer(
  initialState,
  on(fromActions.normalizeVideoUserData, (state, { data }) =>
    adapter.upsertMany(data, state)
  ),
  on(fromActions.saveVideoUserData, (state, { data, tag }) => {
    const { id } = data;
    const entityLoading = {
      ...state.entityLoading,
      [id]: tag === 'Quiet' ? false : true
    };
    const entityError = {
      ...state.entityError,
      [id]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.saveVideoUserDataSuccess, (state, { data }) => {
    const { id } = data;
    const entityLoading = {
      ...state.entityLoading,
      [id]: false
    };
    const entityError = {
      ...state.entityError,
      [id]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.saveVideoUserDataFailure, (state, { id, error }) => {
    const entityLoading = {
      ...state.entityLoading,
      [id]: false
    };
    const entityError = {
      ...state.entityError,
      [id]: error
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return videoUserDataReducer(state, action);
}
