import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { CancelSubscriptionModalComponent } from './cancel-subscription-modal/cancel-subscription-modal.component';
import { CaptchaModalComponent } from './captcha-modal/captcha-modal.component';
import { ChangeSubscriptionPaymentModalComponent } from './change-subscription-payment-modal/change-subscription-payment-modal.component';
import { ChangeSubscriptionPlanModalComponent } from './change-subscription-plan-modal/change-subscription-plan-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { ExistingSubscriptionModalComponent } from './existing-subscription-modal/existing-subscription-modal.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { PlatformsModalComponent } from './platforms-modal/platforms-modal.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { UnactivatedSubscriptionModalComponent } from './unactivated-subscription-modal/unactivated-subscription-modal.component';
import { DisclaimerModalComponent } from './disclaimer-modal/disclaimer-modal.component';
import { IubendaModalComponent } from './iubenda-modal/iubenda-modal.component';

export const config = {
  auth: AuthModalComponent,
  cancelSubscription: CancelSubscriptionModalComponent,
  captcha: CaptchaModalComponent,
  changeSubscriptionPayment: ChangeSubscriptionPaymentModalComponent,
  changeSubscriptionPlan: ChangeSubscriptionPlanModalComponent,
  confirm: ConfirmModalComponent,
  editUser: EditUserModalComponent,
  existingSubscription: ExistingSubscriptionModalComponent,
  help: HelpModalComponent,
  platforms: PlatformsModalComponent,
  share: ShareModalComponent,
  unactivatedSubscription: UnactivatedSubscriptionModalComponent,
  disclaimer: DisclaimerModalComponent,
  iubenda: IubendaModalComponent,
};

export const components = [
  AuthModalComponent,
  CancelSubscriptionModalComponent,
  CaptchaModalComponent,
  ChangeSubscriptionPaymentModalComponent,
  ChangeSubscriptionPlanModalComponent,
  ConfirmModalComponent,
  EditUserModalComponent,
  ExistingSubscriptionModalComponent,
  IubendaModalComponent,
  HelpModalComponent,
  PlatformsModalComponent,
  ShareModalComponent,
  UnactivatedSubscriptionModalComponent,
  DisclaimerModalComponent,
  IubendaModalComponent,
];

export * from './auth-modal/auth-modal.component';
export * from './cancel-subscription-modal/cancel-subscription-modal.component';
export * from './captcha-modal/captcha-modal.component';
export * from './change-subscription-payment-modal/change-subscription-payment-modal.component';
export * from './change-subscription-plan-modal/change-subscription-plan-modal.component';
export * from './confirm-modal/confirm-modal.component';
export * from './edit-user-modal/edit-user-modal.component';
export * from './existing-subscription-modal/existing-subscription-modal.component';
export * from './help-modal/help-modal.component';
export * from './platforms-modal/platforms-modal.component';
export * from './share-modal/share-modal.component';
export * from './unactivated-subscription-modal/unactivated-subscription-modal.component';
export * from './disclaimer-modal/disclaimer-modal.component';
export * from './iubenda-modal/iubenda-modal.component';
