import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'odm-account-logo',
  templateUrl: './account-logo.component.html',
  styleUrls: ['./account-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountLogoComponent implements OnInit {
  @Input() account: any;

  constructor() {}

  ngOnInit() {}

  get imgSrc(): string {
    // cheat missing validation for now. shhh!
    if(
      this.account.uploaded_nav_logo_enabled &&
      (this.account.uploaded_nav_logo)
    ) {
      return this.account.uploaded_nav_logo.url;
    }

    return this.account.logo.url;
  }
}
