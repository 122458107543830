import { Injectable } from '@angular/core';

import { unwrap } from 'src/app/util/code.util';

@Injectable({
  providedIn: 'root',
})
export class CustomTextService {
  accountName: string;
  subscribeText: string;
  subscriptionText: string;
  disclaimerEnabled: boolean;
  channelsTerm: string;
  channelTerm: string;
  featuredTerm: string;
  tagsTerm: string;
  favoritesTerm: string;
  iphoneStoreLink: string;
  ipadStoreLink: string;
  tvosStoreLink: string;
  androidStoreLink: string;
  rokuStoreLink: string;
  amazonStoreLink: string;
  iosAppLive: boolean;
  tvosAppLive: boolean;
  androidAppLive: boolean;
  androidtvAppLive: boolean;
  fireAppLive: boolean;
  firetvAppLive: boolean;
  rokuAppLive: boolean;
  platformCount: number;
  platforms: string;
  appleStoreEnabled: boolean;
  googlePlayEnabled: boolean;
  rokuEnabled: boolean;
  amazonEnabled: boolean;
  supportEmail: string;
  defaultTitle: string;
  iosUrlScheme: string;

  set account(account: any) {
    if (account) {
      this.accountName = account['name'];
      this.subscribeText = account['primary_cta_text'];
      this.subscriptionText = account['primary_cta_supporting_text'];
      this.disclaimerEnabled = account['disclaimer_enabled'];
      this.channelsTerm = account['channels_term'];
      this.channelTerm = account['channel_term'];
      this.featuredTerm = account['favorites_term'];
      this.tagsTerm = account['tags_term'];
      this.favoritesTerm = account['featured_term'];
      this.iphoneStoreLink = account['iphone_store_web_link'];
      this.ipadStoreLink = account['ipad_store_web_link'];
      this.tvosStoreLink = account['tvos_store_web_link'];
      this.androidStoreLink = account['android_store_link'];
      this.rokuStoreLink = account['roku_store_link'];
      this.amazonStoreLink = account['amazon_store_link'];
      this.iosUrlScheme = account['ios_url_scheme'];

      const platforms = [];
      if (this.iphoneStoreLink) platforms.push('iPhone');
      if (this.ipadStoreLink) platforms.push('iPad');
      if (this.tvosStoreLink) platforms.push('AppleTV');
      if (this.androidStoreLink) platforms.push('Android');
      if (this.rokuStoreLink) platforms.push('Roku');
      if (this.amazonStoreLink) platforms.push('FireTV');
      this.platformCount = platforms.length;
      this.platforms =
        platforms.length < 3
          ? platforms.join(' and ')
          : `${platforms.slice(0, -1).join(', ')}, and ${
              platforms[platforms.length - 1]
            }`;
      this.supportEmail = account['technical_support_email'];
    }

    const platformStatuses = unwrap(account,'account_developer_platform_statuses');
    if (platformStatuses) {
      platformStatuses.forEach((platform) => {
        const enabled = !['Coming Soon', 'Pending', 'Opted Out'].includes(
          platform['status']
        );
        switch (platform['developer_platform']) {
          case 'Apple App Store': {
            this.appleStoreEnabled = enabled;
          }
          case 'Google Play': {
            this.googlePlayEnabled = enabled;
          }
          case 'Roku': {
            this.rokuEnabled = enabled;
          }
          case 'Amazon Appstore': {
            this.amazonEnabled = enabled;
          }
        }
      });
    }

    const platformVersions = unwrap(account, 'platform_versions');
    if (platformVersions) {
      platformVersions
        .filter((version) => unwrap(version, '_joinData', 'status') === 'Live')
        .forEach((version) => {

          switch (version['platform_type']) {
            case 'iOS': {
              this.iosAppLive = true;
              break;
            }
            case 'tvOS': {
              this.tvosAppLive = true;
              break;
            }
            case 'Android': {
              this.androidAppLive = true;
              break;
            }
            case 'Android TV': {
              this.androidtvAppLive = true;
              break;
            }
            case 'Fire': {
              this.fireAppLive = true;
              break;
            }
            case 'Fire TV': {
              this.firetvAppLive = true;
              break;
            }
            case 'Roku': {
              this.rokuAppLive = true;
              break;
            }
          }
        });
    }
    this.defaultTitle = unwrap(account, 'page_meta', 'default_title');
  }
}
