import { Injectable, ElementRef } from '@angular/core';

import smoothscroll from 'smoothscroll-polyfill';

@Injectable({
  providedIn: 'root'
})
export class AnchorService {
  anchors: { [key: string]: ElementRef } = {};

  constructor() {
    smoothscroll.polyfill();
  }

  addAnchor(key: string, element: ElementRef) {
    this.anchors[key] = element;
  }

  scrollTo(key: string) {
    const anchor = this.anchors[key];
    if (anchor) {
      anchor.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }
}
