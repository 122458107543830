import { Component, HostListener, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { ClickService } from './core/services/click/click.service';
import { ScrollService } from './core/services/scroll/scroll.service';
import { GoogleAnalyticsService } from './core/services/google-analytics/google-analytics.service';
import * as fromCore from './core/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostListener('click', ['$event.target'])
  onClick(event: HTMLElement) {
    this.clickService.clickTarget$.next(event);
  }

  constructor(
    private router: Router,
    private store: Store<any>,
    private clickService: ClickService,
    private scrollService: ScrollService,
    private ga: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((e: Event) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.ga.pageview(e.urlAfterRedirects);
      });

    // for editing device user agent once if user is authenticated
    const authenticated$ = this.store.pipe(
      select(fromCore.selectDeviceAuthenticated),
      filter((authenticated) => authenticated),
      take(1)
    );
    authenticated$.subscribe(() => {
      this.store.dispatch(fromCore.editDevice());
    });
  }

  scrollToTop() {
    this.scrollService.scrollToTop();
  }
}
