import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { detect } from 'detect-browser';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  constructor(private http: HttpClient) {}

  add(email: string) {
    const browser = detect();
    const user_agent = `${browser.name} ${browser.version} ${browser.os}`;
    const body = { email, user_agent, type: 'web' };
    return this.http.post<any>(
      `${environment.apiUrl2}/devices/add.json`,
      body
    );
  }

  edit(token) {
    const browser = detect();
    const user_agent = `${browser.name} ${browser.version} ${browser.os}`;
    const body = { user_agent };
    return this.http.put<any>(
      `${environment.apiUrl2}/devices/edit/${token}.json`,
      body
    );
  }

  auth(code: string, code2?: string, web_auth?: boolean) {
    const body = { auth_code: code, auth_code_tv: code2, web_auth };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/devices/login_with_auth_code.json`,
      body
    );
  }

  resendAuthEmail() {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/devices/resend_device_login_email.json`
    );
  }

  authTv(code: string, email: string) {
    const body = { auth_code: code, email };
    return this.http.post<any>(
      `${environment.apiUrl2}/devices/claim-auth-code.json`,
      body
    );
  }

  deauth() {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/devices/logout.json`
    );
  }
}
