import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';

import { CustomTextService } from 'src/app/core/services/custom-text/custom-text.service';
import { selectRouteData } from 'src/app/store';
import { selectActiveVideo, selectActiveChannel } from '../../selectors';

@Injectable()
export class HeadTagsEffects {
  navigated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        withLatestFrom(
          this.store.pipe(select(selectActiveVideo)),
          this.store.pipe(select(selectActiveChannel)),
          this.store.pipe(select(selectRouteData))
        ),
        tap(([{ payload }, video, channel, data]) => {
          if (video) {
            this.title.setTitle(
              `${video.title} | ${this.customText.accountName}`
            );
          } else if (channel) {
            this.title.setTitle(
              `${channel.title} | ${this.customText.accountName}`
            );
          } else if (data.title) {
            this.title.setTitle(
              `${data.title} | ${this.customText.accountName}`
            );
          } else if (data.titleKey) {
            const title = this.getTitleForKey(data.titleKey);
            this.title.setTitle(`${title} | ${this.customText.accountName}`);
          } else {
            this.title.setTitle(this.customText.defaultTitle);
          }
        })
      ),
    { dispatch: false }
  );

  getTitleForKey(key: string) {
    switch (key) {
      case 'channels': {
        return `Browse All ${this.customText.channelsTerm}`;
      }
      case 'subscribe': {
        return this.customText.subscribeText;
      }
    }
  }

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private customText: CustomTextService,
    private title: Title,
    private meta: Meta
  ) {}
}
