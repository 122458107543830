import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Store } from '@ngrx/store';

import * as copy from 'copy-to-clipboard';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';
import { Subject } from 'rxjs';

@Component({
  selector: 'odm-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class ShareModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  href = location.origin + location.pathname;
  message$ = new Subject<boolean>();
  constructor(private store: Store<any>) {}

  ngOnInit() {}

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  copyToClipboard() {
    copy(this.href);
    this.message$.next(true);
  }
}
