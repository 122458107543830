import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromChannels from '../../../../../core/store/selectors/channels/channels.selectors';
import * as fromVideos from '../../../../../core/store/selectors/videos/videos.selectors';
import * as fromVideoUserData from '../../../../../core/store/selectors/video-user-data/video-user-data.selectors';

export const selectPagesState = createSelector(
  fromFeature.selectHomeState,
  state => state.pages
);

const selectPagesEntities = createSelector(
  selectPagesState,
  state => state.entities
);

const selectPagesEntityLoading = createSelector(
  selectPagesState,
  state => state.entityLoading
);

const selectPagesEntityError = createSelector(
  selectPagesState,
  state => state.entityError
);

export const selectPagesLoaded = tag =>
  createSelector(selectPagesEntities, entities => !!entities[tag]);

export const selectPagesLoading = tag =>
  createSelector(selectPagesEntityLoading, entityLoading => entityLoading[tag]);

export const selectPagesError = tag =>
  createSelector(selectPagesEntityError, entityError => entityError[tag]);

export const denormalizePage = (
  tag: string,
  pageEntities,
  channelEntities,
  videoEntities,
  videoUserDataEntities
) => {
  const page = pageEntities[tag];
  return (
    page && {
      ...page,
      channel: fromChannels.denormalizeChannel(
        page.channel,
        channelEntities,
        videoEntities,
        videoUserDataEntities
      ),
      hero_channel: fromChannels.denormalizeChannel(
        page.hero_channel,
        channelEntities,
        videoEntities,
        videoUserDataEntities
      ),
      hero_video: fromVideos.denormalizeVideo(
        page.hero_video,
        videoEntities,
        videoUserDataEntities
      ),
      hero_trailer: fromVideos.denormalizeVideo(
        page.hero_trailer,
        videoEntities,
        videoUserDataEntities
      )
    }
  );
};

export const selectPage = tag =>
  createSelector(
    selectPagesEntities,
    fromChannels.selectChannelsEntities,
    fromVideos.selectVideosEntities,
    fromVideoUserData.selectVideoUserDataEntities,
    (pageEntities, channelEntities, videoEntities, videoUserDataEntities) =>
      denormalizePage(
        tag,
        pageEntities,
        channelEntities,
        videoEntities,
        videoUserDataEntities
      )
  );
