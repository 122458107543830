import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectVerifyMobileState = createSelector(
  fromFeature.selectUserState,
  state => state.verifyMobile
);

export const selectVerifyMobileLoaded = createSelector(
  selectVerifyMobileState,
  state => state.loaded
);

export const selectVerifyMobileLoading = createSelector(
  selectVerifyMobileState,
  state => state.loading
);

export const selectVerifyMobileError = createSelector(
  selectVerifyMobileState,
  state => state.error
);
