import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<any>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.selectAccountLoaded),
      filter(loaded => loaded),
      switchMap(() =>
        this.store.pipe(
          select(fromStore.selectUserAuthLoaded),
          tap(loaded => {
            if (!loaded) {
              this.store.dispatch(fromStore.loadUserAuth());
            }
          }),
          filter(loaded => loaded)
        )
      )
    );
  }
}
