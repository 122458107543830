import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectContinueSubscriptionState = createSelector(
  fromFeature.selectUserState,
  state => state.continueSubscription
);

export const selectContinueSubscriptionLoaded = createSelector(
  selectContinueSubscriptionState,
  state => state.loaded
);

export const selectContinueSubscriptionLoading = createSelector(
  selectContinueSubscriptionState,
  state => state.loading
);

export const selectContinueSubscriptionError = createSelector(
  selectContinueSubscriptionState,
  state => state.error
);

export const selectContinueSubscriptionMessage = createSelector(
  selectContinueSubscriptionState,
  state => state.message
);
