import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectMarketingContentState = createSelector(
  fromFeature.selectCoreState,
  state => state.marketingContent
);

export const selectMarketingContent = createSelector(
  selectMarketingContentState,
  state => state.content
);

export const selectMarketingContentLoaded = createSelector(
  selectMarketingContentState,
  state => state.loaded
);

export const selectMarketingContentLoading = createSelector(
  selectMarketingContentState,
  state => state.loading
);

export const selectMarketingContentError = createSelector(
  selectMarketingContentState,
  state => state.error
);
