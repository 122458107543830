import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-unactivated-subscription-modal',
  templateUrl: './unactivated-subscription-modal.component.html',
  styleUrls: ['./unactivated-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class UnactivatedSubscriptionModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  resendSubscriptionEmailLoading$: Observable<boolean>;
  resendSubscriptionEmailError$: Observable<any>;
  deletePendingSubscriptionMessage$: Observable<string>;
  deletePendingSubscriptionLoaded$: Observable<boolean>;
  deletePendingSubscriptionLoading$: Observable<boolean>;
  deletePendingSubscriptionError$: Observable<any>;
  constructor(private store: Store<any>, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.resendSubscriptionEmailLoading$ = this.store.pipe(
      select(fromStore.selectSubscriptionEmailLoading)
    );
    this.resendSubscriptionEmailError$ = this.store.pipe(
      select(fromStore.selectSubscriptionEmailError)
    );

    this.deletePendingSubscriptionMessage$ = this.store.pipe(
      select(fromStore.selectDeletePendingSubscriptionMessage)
    );
    this.deletePendingSubscriptionLoaded$ = this.store.pipe(
      select(fromStore.selectDeletePendingSubscriptionLoaded)
    );
    this.deletePendingSubscriptionLoading$ = this.store.pipe(
      select(fromStore.selectDeletePendingSubscriptionLoading)
    );
    this.deletePendingSubscriptionError$ = this.store.pipe(
      select(fromStore.selectDeletePendingSubscriptionError)
    );
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  trustHtml(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  resendSubscriptionEmail() {
    const { email } = this.options;
    this.store.dispatch(fromStore.resendSubscriptionActivationEmail({ email }));
  }

  deletePendingSubscription() {
    const { email } = this.options;
    this.store.dispatch(fromStore.deletePendingSubscription({ email }));
  }
}
