import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromActions from '../../actions/toasts/toasts.actions';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {}

export const initialState: State = adapter.getInitialState({});

const accountReducer = createReducer(
  initialState,
  on(fromActions.showToast, (state, { toast }) => adapter.addOne(toast, state)),
  on(fromActions.hideToast, (state, { id }) => adapter.removeOne(id, state))
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
