import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  view() {
    return this.http.get<any>(
      `${environment.apiUrl2}/users/view.json`
    );
  }

  edit(body: any) {
    return this.http.put<any>(
      `${environment.apiUrl}/api/2.0/users/edit.json`,
      body
    );
  }

  changeEmailPreference(unsubscribed: boolean) {
    const body = { unsubscribed, unsubscribed_source: 'Web - Account Page' };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/users/toggle_email_preference_with_device.json`,
      body
    );
  }

  verifyEmail(code: string) {
    const body = { verify_code: code };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/users/verify_email.json`,
      body
    );
  }

  verifyMobile(code: string) {
    const body = { mobile_number_verify_code: code };
    return this.http.put<any>(
      `${environment.apiUrl2}/users/verify-mobile-number.json`,
      body
    );
  }
}
