import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectSubscriptionPlansState = createSelector(
  fromFeature.selectUserState,
  state => state.subscriptionPlans
);

export const selectSubscriptionPlans = createSelector(
  selectSubscriptionPlansState,
  state => state.plans
);

export const selectSubscriptionPlansLoaded = createSelector(
  selectSubscriptionPlansState,
  state => state.loaded
);

export const selectSubscriptionPlansLoading = createSelector(
  selectSubscriptionPlansState,
  state => state.loading
);

export const selectSubscriptionPlansError = createSelector(
  selectSubscriptionPlansState,
  state => state.error
);

export const selectSubscriptionPlan = id =>
  createSelector(selectSubscriptionPlans, plans =>
    plans.find(plan => plan.id === id)
  );
