import { createAction, props } from '@ngrx/store';

export const continueSubscription = createAction(
  '[Continue Subscription] Continue Subscription'
);

export const continueSubscriptionSuccess = createAction(
  '[Continue Subscription] Continue Subscription Success',
  props<{ message: string }>()
);

export const continueSubscriptionFailure = createAction(
  '[Continue Subscription] Continue Subscription Failure',
  props<{ error: any }>()
);
