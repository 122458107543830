import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromReducer from '../../reducers/toasts/toasts.reducer';
import * as fromRoot from '../../../../store';

export const selectModalsState = createSelector(
  fromFeature.selectCoreState,
  state => state.modals
);

const { selectAll, selectTotal } = fromReducer.adapter.getSelectors();

export const selectModals = createSelector(
  selectModalsState,
  selectAll
);

export const selectModalsTotal = createSelector(
  selectModalsState,
  selectTotal
);

export const selectModalsOpen = createSelector(
  selectModalsTotal,
  fromRoot.selectChannelId,
  fromRoot.selectVideoId,
  (total, channelId, videoId) => !!(total > 0 || channelId || videoId)
);
