import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectActivateTVState = createSelector(
  fromFeature.selectUserState,
  state => state.activateTV
);

export const selectActivateTVActivated = createSelector(
  selectActivateTVState,
  state => state.activated
);

export const selectActivateTVMessage = createSelector(
  selectActivateTVState,
  state => state.message
);

export const selectActivateTVLoaded = createSelector(
  selectActivateTVState,
  state => state.loaded
);

export const selectActivateTVLoading = createSelector(
  selectActivateTVState,
  state => state.loading
);

export const selectActivateTVError = createSelector(
  selectActivateTVState,
  state => state.error
);
