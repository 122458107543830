import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'odm-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCarouselComponent implements OnInit {
  @Input() videos: any[];
  @Input() channelId: string;
  @Input() loaded: boolean;
  @Input() loading: boolean;
  @Input() error: any;
  @Input() emptyMessage = 'No videos';
  constructor() {}

  ngOnInit() {}
}
