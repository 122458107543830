import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, finalize, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class EmbedVideoGuard implements CanActivate {
  constructor(private store: Store<any>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const id = route.params.videoId;
    let loading: boolean;
    return this.store.pipe(
      select(fromStore.selectAccountLoaded),
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.loadAccount());
        }
      }),
      filter(loaded => loaded),
      switchMap(() =>
        this.store.pipe(
          select(fromStore.selectVideoLoaded(id)),
          tap(loaded => {
            if (!loaded) {
              this.store.dispatch(fromStore.loadVideo({ id }));
              this.store.dispatch(fromStore.increaseLoadingCount());
              loading = true;
            }
          }),
          finalize(() => {
            if (loading) {
              this.store.dispatch(fromStore.decreaseLoadingCount());
            }
          }),
          filter(loaded => loaded)
        )
      )
    );
  }
}
