import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/videos/videos.actions';
import * as fromVideoUserData from '../../actions/video-user-data/video-user-data.actions';
import * as fromToasts from '../../actions/toasts/toasts.actions';
import { VideosService } from '../../../../api/services';

@Injectable()
export class VideosEffects {
  loadVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVideo),
      mergeMap(({ id }) =>
        this.videoService.view(id).pipe(
          map(video => fromActions.loadVideoSuccess({ video })),
          catchError(error => of(fromActions.loadVideoFailure({ id, error })))
        )
      )
    )
  );

  // loadVideos$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.loadVideos),
  //     mergeMap(({ tag }) => {
  //       if (tag === 'Watched') {
  //         return this.videoService.recentlyWatched().pipe(
  //           map(resp =>
  //             fromActions.loadVideosSuccess({
  //               tag,
  //               videos: resp['Videos'].map(item => item['Video'])
  //             })
  //           ),
  //           catchError(error =>
  //             of(fromActions.loadVideosFailure({ tag, error }))
  //           )
  //         );
  //       } else if (tag === 'Continue') {
  //         return this.videoService.lastWatched().pipe(
  //           map(resp =>
  //             fromActions.loadVideosSuccess({
  //               tag,
  //               videos: [resp]
  //             })
  //           ),
  //           catchError(error =>
  //             of(fromActions.loadVideosFailure({ tag, error }))
  //           )
  //         );
  //       } else if (tag === 'Added') {
  //         return this.videoService.recentlyAdded().pipe(
  //           map(resp =>
  //             fromActions.loadVideosSuccess({
  //               tag,
  //               videos: resp['Videos'].map(item => item['Video'])
  //             })
  //           ),
  //           catchError(error =>
  //             of(fromActions.loadVideosFailure({ tag, error }))
  //           )
  //         );
  //       } else {
  //         return this.videoService.list(tag).pipe(
  //           map(resp =>
  //             fromActions.loadVideosSuccess({ tag, videos: resp['Videos'] })
  //           ),
  //           catchError(error =>
  //             of(fromActions.loadVideosFailure({ tag, error }))
  //           )
  //         );
  //       }
  //     })
  //   )
  // );

  loadVideoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVideoSuccess),
      map(({ video }) => {
        const videos = [video];
        return fromActions.normalizeVideos({ videos });
      })
    )
  );

  loadVideosSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVideosSuccess),
      map(({ videos }) => fromActions.normalizeVideos({ videos }))
    )
  );

  normalizeVideos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.normalizeVideos),
      map(({ videos }) => {
        const data = videos
          .reduce((acc, cur) => [...acc, cur['video_user_data']], [])
          .filter(data => data && data.id);
        return fromVideoUserData.normalizeVideoUserData({ data });
      })
    )
  );

  favoriteVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.favoriteVideo),
      map(({ video, favorite }) => {
        const data = { id: video.data.id, favorite };
        const tag = 'Favorite';
        return fromVideoUserData.saveVideoUserData({ data, tag, video });
      })
    )
  );

  favoriteVideoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.favoriteVideoSuccess),
      map(({ video, favorite }) => {
        const message = favorite
          ? `<strong>${video.title}</strong> was added to your favorites.`
          : `<strong>${video.title}</strong> was removed from your favorites.`;
        const title = favorite
          ? `Added to Favorites`
          : `Removed from Favorites`;
        const icon = favorite
          ? `assets/icons/material-design-icons/favorite/outline.svg`
          : `assets/icons/material-design-icons/favorite_border/outline.svg`;
        const toast = {
          title,
          message,
          icon
        };
        return fromToasts.showToast({ toast });
      })
    )
  );

  constructor(private actions$: Actions, private videoService: VideosService) {}
}
