import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/subscription-eligibility/subscription-eligibility.actions';

export interface State {
  result: string;
  message: string;
  allowCoupon: boolean;
  loading: boolean;
}

export const initialState: State = {
  result: undefined,
  message: undefined,
  allowCoupon: true,
  loading: false
};

const subscriptionPlansReducer = createReducer(
  initialState,
  on(fromActions.checkSubscriptionEligibility, state => ({
    ...state,
    result: undefined,
    message: undefined,
    allowCoupon: true,
    loading: true
  })),
  on(
    fromActions.checkSubscriptionEligibilitySuccess,
    (state, { eligibility }) => {
      const { result, message } = eligibility;
      return {
        ...state,
        result,
        message,
        allowCoupon: !eligibility['coupon_code_removed'],
        loading: false
      };
    }
  ),
  on(fromActions.checkSubscriptionEligibilityFailure, (state, { message }) => ({
    ...state,
    message,
    result: undefined,
    allowCoupon: true,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return subscriptionPlansReducer(state, action);
}
