import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectPromotionState = createSelector(
  fromFeature.selectCoreState,
  state => state.promotion
);

export const selectPromotion = createSelector(
  selectPromotionState,
  state => state.promotion
);

export const selectPromotionLoaded = createSelector(
  selectPromotionState,
  state => state.loaded
);

export const selectPromotionLoading = createSelector(
  selectPromotionState,
  state => state.loading
);

export const selectPromotionError = createSelector(
  selectPromotionState,
  state => state.error
);
