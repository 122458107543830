import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

import { CustomTextService } from 'src/app/core/services/custom-text/custom-text.service';
import { unwrap } from 'src/app/util/code.util';

@Component({
  selector: 'odm-page-channel',
  templateUrl: './page-channel.component.html',
  styleUrls: ['./page-channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageChannelComponent implements OnInit {
  /**
   * Can take in a normal channel object
   * or a content object with a custom "mini-channel"
   */
  @Input() channel: any;
  @Input() content: any;
  constructor(public customText: CustomTextService) {}

  ngOnInit() {

  }



  get videos(): any {
    if(this.channel) {
      return this.channel.videos;
    }
    return this.content && (this.content.preview_video_list.videos);
  }

  get channelId(): any {
    if(this.channel) {
      return this.channel.id;
    }
    return this.content && (this.content.preview_video_list.id);
  }

  get title(): any {
    return unwrap(this.content, 'preview_content_title') || unwrap(this.channel, 'title');
  }

  get description(): any {
    return unwrap(this.content, 'preview_content_description') || unwrap(this.channel, 'description');
  }
}
