import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import * as fromInterceptors from './interceptors';
import * as fromServices from './services';

@NgModule({
  imports: [HttpClientModule],
  providers: [...fromInterceptors.interceptors, ...fromServices.services]
})
export class ApiModule {}
