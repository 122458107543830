import { Component, OnInit, ChangeDetectionStrategy, Renderer2 } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import { fadeAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class PromotionComponent implements OnInit {
  promotion$: Observable<any>;

  constructor(private store: Store<any>, private renderer: Renderer2) { }

  ngOnInit() {
    this.promotion$ = this.store.pipe(select(fromStore.selectPromotion));
    this.promotion$.subscribe((promotion) => {
      if (promotion) {
        this.renderer.addClass(document.body, 'has-promotion');
      } else {
        this.renderer.removeClass(document.body, 'has-promotion');
      }
    });
  }

  clearPromotion() {
    this.store.dispatch(fromStore.clearPromotion());
  }
}
