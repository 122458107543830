import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/subscription-eligibility/subscription-eligibility.actions';
import { SubscriptionsService } from '../../../../../api/services';
import { unwrap } from '../../../../../util/code.util';

@Injectable()
export class SubscriptionEligibilityEffects {
  checkSubscriptionEligibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkSubscriptionEligibility),
      mergeMap(({ email, couponId, planId }) =>
        this.subscriptionsService
          .checkEligibility(email, couponId, planId)
          .pipe(
            map(eligibility =>
              fromActions.checkSubscriptionEligibilitySuccess({
                eligibility
              })
            ),
            catchError(error => {
              const message = unwrap(error, 'error', 'message') || 'Error';
              return of(
                fromActions.checkSubscriptionEligibilityFailure({ message })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
