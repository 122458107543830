import { createAction, props } from '@ngrx/store';

export const resendSubscriptionActivationEmail = createAction(
  '[Subscription Email] Resend Activation',
  props<{ email: any }>()
);

export const resendSubscriptionActivationEmailSuccess = createAction(
  '[Subscription Email] Resend Activation Success'
);

export const resendSubscriptionActivationEmailFailure = createAction(
  '[Subscription Email] Resend Activation Failure',
  props<{ error: any }>()
);
