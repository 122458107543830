import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/text-comm/text-comm.actions';
import { TextCommunicationsService } from '../../../../../api/services';

@Injectable()
export class TextCommEffects {
  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendAppLink),
      mergeMap(({ phone, token }) =>
        this.textCommunicationsService.sendAppLink(phone, token).pipe(
          map(() => fromActions.sendAppLinkSuccess()),
          catchError(error => of(fromActions.sendAppLinkFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private textCommunicationsService: TextCommunicationsService
  ) { }
}
