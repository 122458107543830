import { createAction, props } from '@ngrx/store';

export const loadSubscriptionPlans = createAction(
  '[Subscription Plans] Load Subscription Plans'
);

export const loadSubscriptionPlansSuccess = createAction(
  '[Subscription Plans] Load Subscription Plans Success',
  props<{ plans: any[] }>()
);

export const loadSubscriptionPlansFailure = createAction(
  '[Subscription Plans] Load Subscription Plans Failure',
  props<{ error: any }>()
);
