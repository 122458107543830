import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/coupon/coupon.actions';

export interface State {
  id: string;
  loading: boolean;
  message: string;
  data: any;
  error: any;
  count: number;
  countLoaded: boolean;
  countLoading: boolean;
  countError: any;
}

export const initialState: State = {
  id: undefined,
  loading: false,
  message: '',
  data: undefined,
  error: undefined,
  count: 0,
  countLoaded: false,
  countLoading: false,
  countError: undefined
};

const couponReducer = createReducer(
  initialState,
  on(fromActions.checkCoupon, state => ({
    ...state,
    id: undefined,
    loading: true,
    message: undefined,
    error: undefined
  })),
  on(fromActions.checkCouponSuccess, (state, { id, message, data }) => ({
    ...state,
    id,
    message,
    data,
    loading: false
  })),
  on(fromActions.checkCouponFailure, (state, { error }) => ({
    ...state,
    error,
    id: undefined,
    loading: false
  })),
  on(fromActions.clearCoupon, state => ({
    ...state,
    id: undefined,
    loading: false,
    message: undefined
  })),
  on(fromActions.loadCouponCount, state => ({
    ...state,
    countLoaded: false,
    countLoading: true,
    countError: undefined
  })),
  on(fromActions.loadCouponCountSuccess, (state, { count }) => ({
    ...state,
    count,
    countLoaded: true,
    countLoading: false,
    countError: undefined
  })),
  on(fromActions.loadCouponCountFailure, (state, { error }) => ({
    ...state,
    countLoaded: false,
    countLoading: false,
    countError: error
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return couponReducer(state, action);
}
