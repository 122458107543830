import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectCancelSubscriptionState = createSelector(
  fromFeature.selectUserState,
  state => state.cancelSubscription
);

export const selectCancelSubscriptionLoaded = createSelector(
  selectCancelSubscriptionState,
  state => state.loaded
);

export const selectCancelSubscriptionLoading = createSelector(
  selectCancelSubscriptionState,
  state => state.loading
);

export const selectCancelSubscriptionError = createSelector(
  selectCancelSubscriptionState,
  state => state.error
);

export const selectCancelSubscriptionMessage = createSelector(
  selectCancelSubscriptionState,
  state => state.message
);
