import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/pages/pages.actions';
import { unwrap } from 'src/app/util/code.util';

export interface State {
  entities: { [tag: string]: any };
  entityLoading: { [tag: string]: boolean };
  entityError: { [tag: string]: any };
}

export const initialState: State = {
  entities: {},
  entityLoading: {},
  entityError: {}
};

const pageReducer = createReducer(
  initialState,
  on(fromActions.normalizePage, (state, { tag, page }) => {
    const normalized = {
      ...page,
      channel:
        unwrap(page, 'FeaturedChannel', 'id') ||
        unwrap(page, 'PreviewContentChannel', 'id'),
      hero_channel: unwrap(page, 'HeroChannel', 'id'),
      hero_video: unwrap(page, 'HeroVideo', 'id'),
      hero_trailer: unwrap(page, 'HeroTrailerVideo', 'id')
    };
    const entities = { ...state.entities, [tag]: normalized };
    return { ...state, entities };
  }),
  on(fromActions.loadPage, (state, { tag }) => {
    const entityLoading = {
      ...state.entityLoading,
      [tag]: true
    };
    const entityError = {
      ...state.entityError,
      [tag]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadPageSuccess, (state, { tag, page }) => {
    const entityLoading = {
      ...state.entityLoading,
      [tag]: false
    };
    const entityError = {
      ...state.entityError,
      [tag]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadPageFailure, (state, { tag, error }) => {
    const entityLoading = {
      ...state.entityLoading,
      [tag]: false
    };
    const entityError = {
      ...state.entityError,
      [tag]: error
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return pageReducer(state, action);
}
