import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromPages from './pages/pages.reducer';
import * as fromTextComm from './text-comm/text-comm.reducer';

export const featureKey = 'home';

export interface State {
  pages: fromPages.State;
  textComm: fromTextComm.State;
}

export const reducers: ActionReducerMap<State> = {
  pages: fromPages.reducer,
  textComm: fromTextComm.reducer
};

export const selectHomeState = createFeatureSelector<State>(featureKey);
