import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/subscription-email/subscription-email.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: undefined
};

const subscriptionEmailReducer = createReducer(
  initialState,
  on(fromActions.resendSubscriptionActivationEmail, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.resendSubscriptionActivationEmailSuccess, state => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(
    fromActions.resendSubscriptionActivationEmailFailure,
    (state, { error }) => ({
      ...state,
      error,
      loaded: false,
      loading: false
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return subscriptionEmailReducer(state, action);
}
