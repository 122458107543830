import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as fromActions from '../../actions/channels/channels.actions';
import * as fromVideos from '../../actions/videos/videos.actions';
import { ChannelsService } from '../../../../api/services';

@Injectable()
export class ChannelsEffects {
  loadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChannels),
      mergeMap(() =>
        this.channelsService.list().pipe(
          map(channels =>
            fromActions.loadChannelsSuccess({ channels })
          ),
          catchError(error => of(fromActions.loadChannelsFailure({ error })))
        )
      )
    )
  );

  loadChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChannel),
      mergeMap(({ id }) =>
        this.channelsService.view(id).pipe(
          map(channel => fromActions.loadChannelSuccess({ channel })),
          catchError(error => of(fromActions.loadChannelFailure({ id, error })))
        )
      )
    )
  );

  loadChannelsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChannelsSuccess),
      map(({ channels }) => fromActions.normalizeChannels({ channels }))
    )
  );

  loadChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChannelSuccess),
      map(({ channel }) => {
        const channels = [channel];

        return fromActions.normalizeChannels({ channels });
      })
    )
  );

  normalizeChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.normalizeChannels),
      map(({ channels }) => {
        const videos = channels
          .reduce(
            (acc, cur) => [
              ...acc,
              cur['trailer_video'],
              ...cur['videos']],
            []
          )
          .filter(video => video && video.id);
        return fromVideos.normalizeVideos({ videos });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private channelsService: ChannelsService
  ) {}
}
