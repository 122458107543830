import { createAction, props } from '@ngrx/store';

export const loadMarketingContent = createAction('[Marketing Content] Load Marketing Content');

export const loadMarketingContentSuccess = createAction(
  '[Marketing Content] Load Marketing Content Success',
  props<{ content: any }>()
);

export const loadMarketingContentFailure = createAction(
  '[Marketing Content] Load Marketing Content Failure',
  props<{ error: any }>()
);
