import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, characterLimit: number): string {
    if (value) {
      var limit;
      const spaceIdx = value.substring(0, characterLimit + 1).lastIndexOf(' ');
      
      // If no spaces, truncate at any character
      if (spaceIdx < 1) {
        limit = characterLimit;
      } else {
        limit = spaceIdx;
      }

      return value.length > characterLimit ? value.substr(0, limit) + '...' : value;
    } else {
      return '';
    }
  }
}
