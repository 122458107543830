import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'odm-watch-anywhere',
  templateUrl: './watch-anywhere.component.html',
  styleUrls: ['./watch-anywhere.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WatchAnywhereComponent implements OnInit {
  @Input() content: any;
  constructor() {}

  ngOnInit() {}
}
