import { createAction, props } from '@ngrx/store';

export const checkCoupon = createAction(
  '[Coupon] Check Coupon',
  props<{ code: string; planId: string }>()
);

export const checkCouponSuccess = createAction(
  '[Coupon] Check Coupon Success',
  props<{ id: string; message: string, data: any }>()
);

export const checkCouponFailure = createAction(
  '[Coupon] Check Coupon Failure',
  props<{ error: any }>()
);

export const clearCoupon = createAction('[Coupon] Clear Coupon');

export const loadCouponCount = createAction('[Coupon] Load Coupon Count');

export const loadCouponCountSuccess = createAction(
  '[Coupon] Load Coupon Count Success',
  props<{ count: number }>()
);

export const loadCouponCountFailure = createAction(
  '[Coupon] Load Coupon Count Failure',
  props<{ error: any }>()
);
