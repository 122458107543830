import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/security/security.actions';

export interface State {
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
  email: string
  verification_hash: string
}

export const initialState: State = {
  message: undefined,
  loaded: false,
  loading: false,
  error: undefined,
  email: undefined,
  verification_hash: undefined,
};

const securityReducer = createReducer(
  initialState,
  on(fromActions.securitySubmitEmail, state => ({
    ...state,
    message: undefined,
    loaded: false,
    loading: true,
    error: undefined,
    email: undefined,
    verification_hash: undefined,
  })),
  on(fromActions.securitySubmitEmailSuccess, (state, {message}) => ({
    ...state,
    message,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.securitySubmitEmailFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  })),
  on(fromActions.securitySetValues, (state, { email, verification_hash }) => ({
    ...state,
    email,
    verification_hash,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return securityReducer(state, action);
}
