import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectVerifyEmailState = createSelector(
  fromFeature.selectUserState,
  state => state.verifyEmail
);

export const selectVerifyEmailLoaded = createSelector(
  selectVerifyEmailState,
  state => state.loaded
);

export const selectVerifyEmailLoading = createSelector(
  selectVerifyEmailState,
  state => state.loading
);

export const selectVerifyEmailError = createSelector(
  selectVerifyEmailState,
  state => state.error
);
