import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectUserState = createSelector(
  fromFeature.selectCoreState,
  state => state.user
);

export const selectUser = createSelector(selectUserState, state => state.user);

export const selectUserLoaded = createSelector(
  selectUserState,
  state => state.loaded
);

export const selectUserLoading = createSelector(
  selectUserState,
  state => state.loading
);

export const selectUserLoadAttempted = createSelector(
  selectUserState,
  state => state.loadAttempted
);

export const selectUserError = createSelector(
  selectUserState,
  state => state.error
);

export const selectUserLocked = createSelector(
  selectUserState,
  state => state.locked
);

export const selectUserEditLoaded = createSelector(
  selectUserState,
  state => state.editLoaded
);

export const selectUserEditLoading = createSelector(
  selectUserState,
  state => state.editLoading
);

export const selectUserEditMessage = createSelector(
  selectUserState,
  state => state.editMessage
);

export const selectUserEditError = createSelector(
  selectUserState,
  state => state.editError
);

export const selectUserEmailPreference = createSelector(
  selectUserState,
  state => state.emailPreference
);

export const selectUserEmailPreferenceLoaded = createSelector(
  selectUserState,
  state => state.emailPreferenceLoaded
);

export const selectUserEmailPreferenceLoading = createSelector(
  selectUserState,
  state => state.emailPreferenceLoading
);

export const selectUserEmailPreferenceError = createSelector(
  selectUserState,
  state => state.emailPreferenceError
);

export const selectUserAuthenticated = createSelector(
  selectUserState,
  state => state.authenticated
);

export const selectUserAuthLoaded = createSelector(
  selectUserState,
  state => state.authLoaded
);

export const selectUserAuthLoading = createSelector(
  selectUserState,
  state => state.authLoading
);
