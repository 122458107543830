import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectSubscriberContentState = createSelector(
  fromFeature.selectCoreState,
  state => state.subscriberContent
);

export const selectSubscriberContent = createSelector(
  selectSubscriberContentState,
  state => state.content
);

export const selectSubscriberContentLoaded = createSelector(
  selectSubscriberContentState,
  state => state.loaded
);

export const selectSubscriberContentLoading = createSelector(
  selectSubscriberContentState,
  state => state.loading
);

export const selectSubscriberContentError = createSelector(
  selectSubscriberContentState,
  state => state.error
);
