import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromRoot from '../../../store';

@Component({
  selector: 'odm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  account$: Observable<any>;
  routeData$: Observable<any>;
  year = new Date().getFullYear();
  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromStore.selectAccount));
    this.routeData$ = this.store.pipe(select(fromRoot.selectRouteData));
  }

  openHelpModal() {
    this.store.dispatch(fromStore.openModal({ key: 'help' }));
  }

  openDisclaimerModal() {
    this.store.dispatch(fromStore.openModal({ key: 'disclaimer' }));
  }

  openIubendaModal(type?) {
    const options = { type };
    this.store.dispatch(fromStore.openModal({ key: 'iubenda', options }));
  }
}
