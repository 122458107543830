import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromActions from '../../actions/videos/videos.actions';
import { unwrap } from '../../../../util/code.util';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  entityLoading: { [id: string]: boolean };
  entityError: { [id: string]: any };
  tagIds: { [tag: string]: string[] };
  tagLoading: { [tag: string]: boolean };
  tagError: { [tag: string]: any };
  favoritesLoaded: boolean;
}

export const initialState: State = adapter.getInitialState({
  entityLoading: {},
  entityError: {},
  tagIds: {},
  tagLoading: {},
  tagError: {},
  favoritesLoaded: false
});

const videosReducer = createReducer(
  initialState,
  on(fromActions.normalizeVideos, (state, { videos }) => {
    const normalized = videos
    .filter(video => !state.entities[video.id] || (video.hls_url))
    .map(video => {
      const data =
        unwrap(video, 'video_user_data', 'id') ||
        unwrap(state, 'entities', video.id, 'data');
      return {
        ...video,
        data
      };
    });
    return adapter.upsertMany(normalized, state);
  }),
  on(fromActions.loadVideo, (state, { id }) => {
    const entityLoading = {
      ...state.entityLoading,
      [id]: true
    };
    const entityError = {
      ...state.entityError,
      [id]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadVideoSuccess, (state, { video }) => {
    const { id } = video;
    const entityLoading = {
      ...state.entityLoading,
      [id]: false
    };
    const entityError = {
      ...state.entityError,
      [id]: undefined
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadVideoFailure, (state, { id, error }) => {
    const entityLoading = {
      ...state.entityLoading,
      [id]: false
    };
    const entityError = {
      ...state.entityError,
      [id]: error
    };
    return {
      ...state,
      entityLoading,
      entityError
    };
  }),
  on(fromActions.loadVideos, (state, { tag }) => {
    const tagLoading = {
      ...state.tagLoading,
      [tag]: true
    };
    const tagError = {
      ...state.tagError,
      [tag]: undefined
    };
    return {
      ...state,
      tagLoading,
      tagError
    };
  }),
  on(fromActions.loadVideosSuccess, (state, { tag, videos }) => {
    const tagIds = {
      ...state.tagIds,
      [tag]: videos.map(video => video['Video']['id'])
    };
    const tagLoading = {
      ...state.tagLoading,
      [tag]: false
    };
    const tagError = {
      ...state.tagError,
      [tag]: undefined
    };
    const favoritesLoaded = tag === 'Favorite' ? true : state.favoritesLoaded;
    return {
      ...state,
      tagIds,
      tagLoading,
      tagError,
      favoritesLoaded
    };
  }),
  on(fromActions.loadVideosFailure, (state, { tag, error }) => {
    const tagLoading = {
      ...state.tagLoading,
      [tag]: false
    };
    const tagError = {
      ...state.tagError,
      [tag]: error
    };
    return {
      ...state,
      tagLoading,
      tagError
    };
  }),
  on(fromActions.favoriteVideoSuccess, (state, { video, favorite }) => {
    const tag = 'Favorite';
    const ids = state.tagIds[tag] || [];
    let tagIds;
    if (favorite) {
      tagIds = { ...state.tagIds, [tag]: [...ids, video.id] };
    } else {
      tagIds = { ...state.tagIds, [tag]: ids.filter(id => id !== video.id) };
    }
    return { ...state, tagIds };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return videosReducer(state, action);
}
