import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StreamKeysService {
  constructor(private http: HttpClient) {}

  live() {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/stream_keys/currently_live.json`
    );
  }
}
