import { createAction, props } from '@ngrx/store';

export const securitySubmitEmail = createAction(
  '[Security] Submit Email',
  props<{ email: string }>()
);

export const securitySubmitEmailSuccess = createAction(
  '[Security] Submit Email Success',
  props<{ message: string }>()

);

export const securitySubmitEmailFailure = createAction(
  '[Security] Submit Email Failure',
  props<{ error: any }>()
);

export const securitySetValues = createAction(
  '[Security] Submit Set Values',
  props<{ email: string, verification_hash: string }>()
);
