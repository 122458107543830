import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, delay } from 'rxjs/operators';

import * as fromActions from '../../actions/toasts/toasts.actions';

@Injectable()
export class ToastsEffects {
  showToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showToast),
      delay(5000),
      map(({ toast }) => {
        const { id } = toast;
        return fromActions.hideToast({ id });
      })
    )
  );

  constructor(private actions$: Actions) {}
}
