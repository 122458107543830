import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as chroma from 'chroma-js';

import * as fromActions from '../../actions/account/account.actions';
import * as fromPromotion from '../../actions/promotion/promotion.actions';
import * as fromStreamKey from '../../actions/stream-key/stream-key.actions';
import * as fromVideos from '../../actions/videos/videos.actions';
import { AccountsService } from '../../../../api/services';
import { CustomTextService } from '../../../../core/services/custom-text/custom-text.service';
import { unwrap } from 'src/app/util/code.util';

@Injectable()
export class AccountEffects {
  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAccount),
      mergeMap(() =>
        this.accountsService.view().pipe(
          map(account => fromActions.loadAccountSuccess({ account })),
          catchError(error => of(fromActions.loadAccountFailure({ error })))
        )
      )
    )
  );

  loadAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAccountSuccess),
      tap(({ account }) => {
        // custom text for easy cache access
        this.customText.account = account;
        // setup colors
        const navBackgroundColor = chroma(account.nav_background_color);
        document.documentElement.style.setProperty(
          '--nav-background-color',
          navBackgroundColor.hex()
        );
        document.documentElement.style.setProperty(
          '--nav-background-color-active',
          navBackgroundColor
            .darken(0.5)
            .desaturate(0.5)
            .hex()
        );
        document.documentElement.style.setProperty(
          '--nav-background-color-deep',
          navBackgroundColor
            .darken(0.8)
            .desaturate(0.8)
            .hex()
        );

        const accent = chroma(account.accent_color);
        document.documentElement.style.setProperty(
          '--theme-color',
          accent.hex()
        );
        document.documentElement.style.setProperty(
          '--theme-color-active',
          accent
            .darken(0.5)
            .desaturate(0.5)
            .hex()
        );
        document.documentElement.style.setProperty(
          '--theme-color-deep',
          accent
            .darken(0.8)
            .desaturate(0.8)
            .hex()
        );
        document.documentElement.style.setProperty(
          '--theme-color-shadow',
          accent.alpha(0.5).hex('rgba')
        );

        // Add fallback styles for browsers that dont support CSS variables
        const customCss = 'odm-nav.is-overlapping header, header, .action-ribbon, .icon-block .icon-figure, .modal-content.at-top .modal-super-header, .progress-bar, .tile-flag { background-color: '+accent.hex()+'; } .tile-icon, .toast-header svg-icon { color: '+accent.hex()+'; }';
        const docHead = document.head;
        const styleElem = document.createElement('style');

        docHead.appendChild(styleElem);
        styleElem.type = 'text/css';
        styleElem.appendChild(document.createTextNode(customCss));

        // update meta data
        // const title = unwrap(account, 'Account', 'page_title');
        // const description = unwrap(account, 'Account', 'seo_meta_description');
        // this.title.setTitle(title);
        // this.meta.addTag({
        //   name: 'og:title',
        //   content: unwrap(account, 'Account', 'facebook_sharing_title') || title
        // });
        // this.meta.addTag({
        //   name: 'og:description',
        //   content:
        //     unwrap(account, 'Account', 'facebook_sharing_description') ||
        //     description
        // });
        // this.meta.addTag({
        //   name: 'og:image',
        //   content: unwrap(account, 'FacebookSharing', 'url')
        // });
        // this.meta.addTag({
        //   name: 'twitter:title',
        //   content: unwrap(account, 'Account', 'twitter_sharing_title') || title
        // });
        // this.meta.addTag({
        //   name: 'twitter:description',
        //   content:
        //     unwrap(account, 'Account', 'twitter_sharing_description') ||
        //     description
        // });
        // this.meta.addTag({
        //   name: 'twitter:image',
        //   content: unwrap(account, 'TwitterSharing', 'url')
        // });
      }),
      mergeMap(() =>
        of(
          fromPromotion.loadPromotion(),
          fromVideos.loadVideos({ tag: 'Continue' })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountsService: AccountsService,
    private customText: CustomTextService,
    private title: Title,
    private meta: Meta
  ) {}
}
