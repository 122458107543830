import { createAction, props } from '@ngrx/store';

export const normalizePage = createAction(
  '[Pages] Normalize Page',
  props<{ tag: string; page: any }>()
);

export const loadPage = createAction(
  '[Pages] Load Page',
  props<{ tag: string }>()
);

export const loadPageSuccess = createAction(
  '[Pages] Load Page Success',
  props<{ tag: string; page: any }>()
);

export const loadPageFailure = createAction(
  '[Pages] Load Page Failure',
  props<{ tag: string; error: any }>()
);
