import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectChangeSubscriptionPlanState = createSelector(
  fromFeature.selectUserState,
  state => state.changePlan
);

export const selectChangeSubscriptionPlanLoaded = createSelector(
  selectChangeSubscriptionPlanState,
  state => state.loaded
);

export const selectChangeSubscriptionPlanLoading = createSelector(
  selectChangeSubscriptionPlanState,
  state => state.loading
);

export const selectChangeSubscriptionPlanError = createSelector(
  selectChangeSubscriptionPlanState,
  state => state.error
);

export const selectChangeSubscriptionPlanMessage = createSelector(
  selectChangeSubscriptionPlanState,
  state => state.message
);
