import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromCore from '../../../core/store';
import { getRandomString } from '../../../util/random.util';
import { fadeAnimation } from '../../../util/animation.util';

@Component({
  selector: 'odm-resource-links',
  templateUrl: './resource-links.component.html',
  styleUrls: ['./resource-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class ResourceLinksComponent implements OnInit {
  @Input() authenticated: boolean;
  @Input() resources: any[];
  @Input() label: string;
  randomId = getRandomString(7);

  constructor(private store: Store<any>) { }

  ngOnInit() { }

  openAuthModal() {
    this.store.dispatch(fromCore.openModal({ key: 'auth' }));
  }
}
