import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/delete-pending-subscription/delete-pending-subscription.actions';
import { SubscriptionsService } from '../../../../api/services';

@Injectable()
export class DeletePendingSubscriptionsEffects {
  deletePendingSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deletePendingSubscription),
      mergeMap(({ email }) =>
        this.subscriptionsService.deletePending(email).pipe(
          map(({ message }) =>
            fromActions.deletePendingSubscriptionSuccess({ message })
          ),
          catchError((error) =>
            of(fromActions.deletePendingSubscriptionFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
