import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, timer } from 'rxjs';
import { takeUntil, switchMap, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromCore from '../../store';
import * as fromRoot from '../../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';
import { KeyboardService } from '../../services/keyboard/keyboard.service';
import { JwplayerService } from '../../services/jwplayer/jwplayer.service';
import { CustomTextService } from '../../services/custom-text/custom-text.service';
import { unwrap } from 'src/app/util/code.util';

@Component({
  selector: 'odm-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.atTop === true) {
      this.positionHeader();
    }
  }

  destroy$ = new Subject<boolean>();
  video: any;
  channel: any;
  nextVideo: any;
  resources$: Observable<any[]>;
  favoriteLoading$: Observable<any>;
  locked$: Observable<boolean>;
  @ViewChild('follower', { static: false }) follower: ElementRef;
  @ViewChild('player', { static: false }) playerContainer: ElementRef;
  playerFixed = false;
  enablePlayerFixed = true;
  player: any;
  didFirstFrameOnce = false;
  prevDataId: number;
  @ViewChild('modalHeader', { static: false }) modalHeader: ElementRef;
  atTop = false;
  superHeaderWidth: number;
  superHeaderXPos: number;
  startTime: number;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<any>,
    private keyboard: KeyboardService,
    private jwplayer: JwplayerService,
    public customText: CustomTextService
  ) {}

  ngOnInit() {
    const time$ = this.store.pipe(
      select(fromRoot.selectQueryTime),
    );
    time$.subscribe(time => {
      this.startTime = parseInt(time);
    });

    this.resources$ = this.store.pipe(
      select(fromCore.selectActiveVideoResources)
    );
    // this.channel$ = this.store.pipe(select(fromCore.selectRelatedChannel));

    this.favoriteLoading$ = this.store.pipe(
      select(fromCore.selectActiveVideo),
      switchMap((video) =>
        this.store.pipe(
          select(
            fromCore.selectVideoUserDataLoading(unwrap(video, 'data', 'id'))
          )
        )
      )
    );
    const video$ = this.store.pipe(
      select(fromCore.selectActiveVideo),
      takeUntil(this.destroy$)
    );
    video$.subscribe((video) => {
      const prevId = unwrap(this.video, 'id');
      const id = unwrap(video, 'id');
      if (prevId !== id) {
        this.onProgress();
      }
      this.nextVideo = null;
      this.video = video;
      this.onView();
      this.changeDetectorRef.markForCheck();
      this.setupVideoPlayer();
    });
    const channel$ = this.store.pipe(
      select(fromCore.selectRelatedChannel),
      takeUntil(this.destroy$)
    );
    channel$.subscribe((channel) => {
      this.channel = channel;
    });
    this.locked$ = this.store.pipe(select(fromCore.selectUserLocked));
    const esc$ = this.keyboard.esc$.pipe(takeUntil(this.destroy$));
    esc$.subscribe(() => {
      this.router.navigate(['', { outlets: { video: null } }]);
    });
  }

  ngAfterViewInit() {
    if (this.playerContainer) {
      this.player = this.jwplayer.getPlayer(this.playerContainer.nativeElement);
      this.setupVideoPlayer();
    }
  }

  ngOnDestroy() {
    this.onProgress();
    if (this.player) {
      this.player.remove();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onScroll() {
    const { top } = this.follower.nativeElement.getBoundingClientRect();
    this.playerFixed = this.enablePlayerFixed && this.isPlaying() && top < 0;

    // Affix modal "super" header to top of screen on scroll
    this.atTop = this.modalHeader.nativeElement.getBoundingClientRect().top < 0;
    this.positionHeader();
  }

  onFavorite(favorite) {
    this.store.dispatch(
      fromCore.favoriteVideo({ video: this.video, favorite })
    );
  }

  openAuthModal() {
    this.store.dispatch(fromCore.openModal({ key: 'auth' }));
  }

  openShareModal(id) {
    const options = { id, type: 'Video' };
    this.store.dispatch(fromCore.openModal({ key: 'share', options }));
  }

  cancelFollow() {
    this.enablePlayerFixed = false;
    this.playerFixed = false;
    if (this.player) {
      this.player.pause();
    }
  }

  private positionHeader() {
    // Affix modal "super" header to top of screen on scroll
    this.superHeaderWidth = this.modalHeader.nativeElement.getBoundingClientRect().width;
    this.superHeaderXPos = this.modalHeader.nativeElement.getBoundingClientRect().left;
  }

  private setupVideoPlayer() {
    if (!this.player || !this.video) {
      return;
    }
    const playlistItem = this.player.getPlaylistItem();
    const mediaid = playlistItem && playlistItem.mediaid;
    if (mediaid === this.video.id) {
      return;
    }
    // this.player.setup({
    //   file: this.video.hls,
    //   image: this.video.thumb1280_url,
    //   mediaid: this.video.id,
    //   autostart: this.autostart
    // });
    this.player.setup({
      playlist: this.video.media_feed_url,
      autostart: this.startTime ? false : true,
      pipIcon: 'disabled',
    });

    this.player.on('firstFrame', () => this.onFirstFrame());
    this.player.on('pause', () => this.onProgress());
    this.player.on('complete', () => this.onComplete());
  }

  private onView() {
    if (
      !this.video ||
      !this.video.data ||
      this.prevDataId === this.video.data.id
    ) {
      return;
    }
    const data = { id: this.video.data.id, viewed: true };
    this.store.dispatch(fromCore.saveVideoUserData({ data, tag: 'Quiet' }));
    this.prevDataId = data.id;
  }

  private onFirstFrame() {
    if (this.didFirstFrameOnce) {
      return;
    }
    this.didFirstFrameOnce = true;
    if (this.startTime) {
      this.player.seek(this.startTime);
    }
    if (!this.video.data) {
      return;
    }
    if (!this.startTime && this.video.data.progress < 0.95) {
      this.player.seek(this.player.getDuration() * this.video.data.progress);
    }
    const data = { id: this.video.data.id, played: true };
    this.store.dispatch(fromCore.saveVideoUserData({ data, tag: 'Quiet' }));
  }

  private onProgress() {
    if (!this.video || !this.video.data || !this.player) {
      return;
    }
    const position = this.player.getPosition();
    const duration = this.player.getDuration();
    if (!position || !duration) {
      return;
    }
    const progress = position / duration;
    if (!progress) {
      return;
    }
    const data = { id: this.video.data.id, progress };
    this.store.dispatch(fromCore.saveVideoUserData({ data, tag: 'Quiet' }));
  }

  private isPlaying(): boolean {
    return (
      this.player &&
      this.player.getState &&
      this.player.getState() === 'playing'
    );
  }

  private onComplete() {
    if (this.channel && this.channel.enabled_auto_play) {
      const curIdx = this.channel.videos.findIndex(v => v.id === this.video.id);
      this.nextVideo = this.channel.videos[curIdx + 1];
      this.changeDetectorRef.markForCheck();
      timer(7000).subscribe(() => {
        this.navigateToNextVideo();
      });
    }
  }

  navigateToNextVideo() {
    if (!this.nextVideo || !this.channel) {
      return;
    }
    const outlets = { video: [this.nextVideo['id'], this.channel['id']] };
    this.router.navigate(['', { outlets }]);
  }
}
