import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/verify-mobile/verify-mobile.actions';
import * as fromUser from '../../../../../core/store/actions/user/user.actions';
import { UsersService } from '../../../../../api/services';

@Injectable()
export class VerifyMobileEffects {
  verifyMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyMobile),
      mergeMap(({ code }) =>
        this.usersService.verifyMobile(code).pipe(
          map((resp) =>
            fromActions.verifyMobileSuccess()
          ),
          catchError((error) =>
            of(fromActions.verifyMobileFailure({ error }))
          )
        )
      )
    )
  );

  verifyMobileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyMobileSuccess),
      map(() => fromUser.loadUser())
    )
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService
  ) { }
}
