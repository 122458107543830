import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectLoadingState = createSelector(
  fromFeature.selectCoreState,
  state => state.loading
);

export const selectLoadingCount = createSelector(
  selectLoadingState,
  state => state.count
);

export const selectLoading = createSelector(
  selectLoadingCount,
  count => count > 0
);
