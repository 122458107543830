import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { interval, of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as chroma from 'chroma-js';

import * as fromActions from '../../actions/stream-key/stream-key.actions';
import * as fromPromotion from '../../actions/promotion/promotion.actions';
import * as fromVideos from '../../actions/videos/videos.actions';
import { StreamKeysService } from '../../../../api/services';
import { CustomTextService } from '../../../../core/services/custom-text/custom-text.service';
import { unwrap } from 'src/app/util/code.util';

@Injectable()
export class StreamKeyEffects {
  startPollingLiveStreamKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startPollingLiveStreamKey),
      mergeMap(() =>
        interval(30000).pipe(map(() => fromActions.loadLiveStreamKey())))
    )
  );

  loadLiveStreamKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLiveStreamKey),
      mergeMap(() =>
        this.streamKeysService.live().pipe(
          map(streamKey => fromActions.loadLiveStreamKeySuccess({ streamKey })),
          catchError(error => of(fromActions.loadLiveStreamKeyFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private streamKeysService: StreamKeysService,
  ) { }
}
