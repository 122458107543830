import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/user/user.actions';
import { unwrap } from '../../../../util/code.util';

export interface State {
  user: any;
  loaded: boolean;
  loading: boolean;
  loadAttempted: boolean;
  error: any;
  locked: boolean;
  editLoaded: boolean;
  editLoading: boolean;
  editMessage: string;
  editError: any;
  emailPreference: boolean;
  emailPreferenceLoaded: boolean;
  emailPreferenceLoading: boolean;
  emailPreferenceError: any;
  authenticated: boolean;
  authLoaded: boolean;
  authLoading: boolean;
}

export const initialState: State = {
  user: undefined,
  loaded: false,
  loading: false,
  loadAttempted: false,
  error: undefined,
  locked: false,
  editLoaded: false,
  editLoading: false,
  editMessage: undefined,
  editError: undefined,
  emailPreference: undefined,
  emailPreferenceLoaded: false,
  emailPreferenceLoading: false,
  emailPreferenceError: undefined,
  authenticated: false,
  authLoaded: false,
  authLoading: false
};

const userReducer = createReducer(
  initialState,
  on(fromActions.loadUser, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.loadUserSuccess, (state, { user }) => ({
    ...state,
    user,
    emailPreference: !unwrap(user, 'user_email_preference', 'unsubscribed'),
    locked: !user.has_active_subscription,
    loaded: true,
    loading: false,
    loadAttempted: true,
    error: undefined,
    authenticated: true,
    authLoaded: true,
    authLoading: false
  })),
  on(fromActions.loadUserFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
    loadAttempted: true
  })),
  on(fromActions.editUserReset, state => ({
    ...state,
    editLoaded: false,
    editLoading: false,
    editMessage: undefined,
    editError: undefined
  })),
  on(fromActions.editUser, state => ({
    ...state,
    editLoaded: false,
    editLoading: true,
    editMessage: undefined,
    editError: undefined
  })),
  on(fromActions.editUserSuccess, (state, { user, message }) => {
    // email needs to be verified before changing
    delete user['email'];
    // mobile_number also verified before change, unless deleting
    if (user['mobile_number']) delete user['mobile_number'];
    return {
      ...state,
      user: { ...state.user, ...user },
      editLoaded: true,
      editLoading: false,
      editMessage: message,
      editError: undefined
    }
  }),
  on(fromActions.editUserFailure, (state, { error }) => ({
    ...state,
    editError: error,
    editLoaded: false,
    editLoading: false,
    editMessage: undefined
  })),
  on(fromActions.changeUserEmailPreference, state => ({
    ...state,
    emailPreferenceLoaded: false,
    emailPreferenceLoading: true,
    emailPreferenceError: undefined
  })),
  on(
    fromActions.changeUserEmailPreferenceSuccess,
    (state, { emailPreference }) => ({
      ...state,
      emailPreference,
      emailPreferenceLoaded: true,
      emailPreferenceLoading: false,
      emailPreferenceError: undefined
    })
  ),
  on(fromActions.changeUserEmailPreferenceFailure, (state, { error }) => ({
    ...state,
    emailPreferenceError: error,
    emailPreferenceLoaded: false,
    emailPreferenceLoading: false
  })),
  on(fromActions.loadUserAuth, state => ({
    ...state,
    authLoaded: false,
    authLoading: true
  })),
  on(fromActions.loadUserAuthFailure, state => ({
    ...state,
    authLoaded: true,
    authLoading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
