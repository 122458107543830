import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromReducer from '../../reducers/videos/videos.reducer';
import * as fromRoot from '../../../../store';
import * as fromVideoUserData from '../video-user-data/video-user-data.selectors';

export const selectVideosState = createSelector(
  fromFeature.selectCoreState,
  state => state.videos
);

const { selectEntities, selectIds } = fromReducer.adapter.getSelectors();

export const selectVideosEntities = createSelector(
  selectVideosState,
  selectEntities
);

export const selectVideosAllIds = createSelector(
  selectVideosState,
  selectIds
);

const selectVideosEntityLoading = createSelector(
  selectVideosState,
  state => state.entityLoading
);

const selectVideosEntityError = createSelector(
  selectVideosState,
  state => state.entityError
);

const selectVideosTagIds = createSelector(
  selectVideosState,
  state => state.tagIds
);

const selectVideosTagLoading = createSelector(
  selectVideosState,
  state => state.tagLoading
);

const selectVideosTagError = createSelector(
  selectVideosState,
  state => state.tagError
);

export const selectVideosFavoritesLoaded = createSelector(
  selectVideosState,
  state => state.favoritesLoaded
);

export const selectVideoLoaded = id =>
  createSelector(selectVideosEntities, entities => !!entities[id]);

export const selectVideoLoading = id =>
  createSelector(selectVideosEntityLoading, entityLoading => entityLoading[id]);

export const selectVideoError = id =>
  createSelector(selectVideosEntityError, entityError => entityError[id]);

export const selectVideosIds = tag =>
  createSelector(selectVideosTagIds, tagIds => tagIds[tag]);

export const selectVideosLoaded = tag =>
  createSelector(selectVideosTagIds, tagIds => !!tagIds[tag]);

export const selectVideosLoading = tag =>
  createSelector(selectVideosTagLoading, tagLoading => tagLoading[tag]);

export const selectVideosError = tag =>
  createSelector(selectVideosTagError, tagError => tagError[tag]);

export const denormalizeVideo = (
  id: string,
  videoEntities,
  videoUserDataEntities
) => {
  const video = videoEntities[id];
  return video && { ...video, data: videoUserDataEntities[video.data] };
};

export const denormalizeVideos = (
  ids: string[],
  videoEntities,
  videoUserDataEntities
) =>
  ids &&
  ids.map(id => denormalizeVideo(id, videoEntities, videoUserDataEntities));

export const selectActiveVideo = createSelector(
  fromRoot.selectVideoId,
  selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeVideo
);

export const selectEmbedVideo = createSelector(
  fromRoot.selectEmbedVideoId,
  selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeVideo
);

export const selectVideos = tag =>
  createSelector(
    selectVideosIds(tag),
    selectVideosEntities,
    fromVideoUserData.selectVideoUserDataEntities,
    denormalizeVideos
  );

export const selectVideosAll = createSelector(
  selectVideosAllIds,
  selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeVideos
);
