import { createAction, props } from '@ngrx/store';

export const loadUser = createAction('[User] Load User');

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ user: any }>()
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);

export const editUserReset = createAction('[User] Edit User Reset');

export const editUser = createAction(
  '[User] Edit User',
  props<{ user: any }>()
);

export const editUserSuccess = createAction(
  '[User] Edit User Success',
  props<{ user: any, message: string }>()
);

export const editUserFailure = createAction(
  '[User] Edit User Failure',
  props<{ error: any }>()
);

export const changeUserEmailPreference = createAction(
  '[User] Change Email Preference',
  props<{ subscribed: boolean }>()
);

export const changeUserEmailPreferenceSuccess = createAction(
  '[User] Change Email Preference Success',
  props<{ emailPreference: boolean }>()
);

export const changeUserEmailPreferenceFailure = createAction(
  '[User] Change Email Preference Failure',
  props<{ error: any }>()
);

export const loadUserAuth = createAction('[User] Load User Auth');

export const loadUserAuthFailure = createAction(
  '[User] Load User Auth Failure'
);
