import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { of } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  tap,
  filter,
  withLatestFrom,
} from 'rxjs/operators';

import * as fromActions from '../../actions/device/device.actions';
import * as fromUsers from '../../actions/user/user.actions';
import * as fromModals from '../../actions/modals/modals.actions';
import * as fromToasts from '../../actions/toasts/toasts.actions';
import * as fromStreamKey from '../../actions/stream-key/stream-key.actions';
import { DevicesService } from '../../../../api/services';
import { unwrap } from 'src/app/util/code.util';
import { selectDeviceAuthenticated, selectDeviceToken } from '../../selectors';

@Injectable()
export class DeviceEffects {
  saveDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveDevice),
      mergeMap(({ email }) =>
        this.devicesService.add(email).pipe(
          map((resp) =>
            fromActions.saveDeviceSuccess({
              token: unwrap(resp, 'user_token'),
              activated: unwrap(resp, 'activated'),
              method: unwrap(resp, 'auth_method'),
            })
          ),
          catchError((error) => of(fromActions.saveDeviceFailure({ error })))
        )
      )
    )
  );

  saveDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveDeviceSuccess),
      filter(({ activated }) => activated),
      map(() => fromUsers.loadUser())
    )
  );

  // checkDevice$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.checkDevice),
  //     mergeMap(() =>
  //       throwError('not valid').pipe(
  //         map(device => fromActions.saveDeviceSuccess({ device })),
  //         catchError(error =>
  //           timer(5000).pipe(map(() => fromActions.checkDevice()))
  //         )
  //       )
  //     )
  //   )
  // );

  authenticateDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.authenticateDevice),
      mergeMap(({ code, code2, deviceOS, web_auth }) =>
        this.devicesService.auth(code, code2, web_auth).pipe(
          map((resp) =>
            fromActions.authenticateDeviceSuccess({
              token: resp['user_token'],
              message: resp['message'],
              type: resp['type'],
              deviceOS
            })
          ),
          catchError((error) =>
            of(fromActions.authenticateDeviceFailure({ error, deviceOS }))
          )
        )
      )
    )
  );

  authenticateDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.authenticateDeviceSuccess),
      filter(({ message }) => !!message),
      map(({ message }) => {
        const toast = {
          title: 'Notice',
          message,
          icon: 'assets/icons/material-design-icons/error/sharp.svg',
        };
        return fromToasts.showToast({ toast });
      })
    )
  );

  authenticateDeviceSuccessEverytime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.authenticateDeviceSuccess),
      mergeMap(() =>
        of(
          fromStreamKey.startPollingLiveStreamKey(),
          fromStreamKey.loadLiveStreamKey(),
        )
      )
    )
  );

  authenticateDeviceFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.authenticateDeviceFailure),
      withLatestFrom(this.store.pipe(select(selectDeviceAuthenticated))),
      mergeMap(([{ error, deviceOS }, authenticated]) => {
        if (deviceOS) {
          const toast = {
            title: 'Please Open the App',
            message:
              'This activation link was already used. Please open the app.',
            icon: 'assets/icons/material-design-icons/error/sharp.svg',
          };
          return of(fromToasts.showToast({ toast }));
        } else if (authenticated) {
          const toast = {
            title: 'All Set',
            message: 'You are already signed in and activated.',
            icon: 'assets/icons/material-design-icons/check_circle/sharp.svg',
          };
          this.router.navigate(['user', 'account']);
          return of(fromToasts.showToast({ toast }));
        } else {
          const toast = {
            title: 'Please Sign In',
            message:
              'This activation link was already used. Please proceed to sign in.',
            icon: 'assets/icons/material-design-icons/error/sharp.svg',
          };
          return of(
            fromToasts.showToast({ toast }),
            fromModals.openModal({ key: 'auth' })
          );
        }
      })
    )
  );

  resendDeviceAuthEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendDeviceAuthEmail),
      mergeMap(() =>
        this.devicesService.resendAuthEmail().pipe(
          map(() => fromActions.resendDeviceAuthEmailSuccess()),
          catchError((error) =>
            of(fromActions.resendDeviceAuthEmailFailure({ error }))
          )
        )
      )
    )
  );

  deauthenticateDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deauthenticateDevice),
      mergeMap(() =>
        this.devicesService.deauth().pipe(
          map(({ message }) =>
            fromActions.deauthenticateDeviceSuccess({ message })
          ),
          catchError((error) =>
            of(fromActions.deauthenticateDeviceFailure({ error }))
          )
        )
      )
    )
  );

  deauthenticateDeviceDone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromActions.deauthenticateDeviceSuccess,
          fromActions.deauthenticateDeviceFailure
        ),
        tap(() => {
          localStorage.clear();
          location.assign('/');
        })
      ),
    { dispatch: false }
  );

  editDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editDevice),
      withLatestFrom(this.store.pipe(select(selectDeviceToken))),
      mergeMap(([_, token]) =>
        this.devicesService.edit(token).pipe(
          map(() => fromActions.editDeviceSuccess()),
          catchError((error) => of(fromActions.editDeviceFailure({ error })))
        )
      )
    )
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<any>,
    private devicesService: DevicesService
  ) { }
}
