import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import intlTelInput from 'intl-tel-input';

declare const $: any;

@Component({
  selector: 'odm-intl-tel-input',
  templateUrl: './intl-tel-input.component.html',
  styleUrls: ['./intl-tel-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntlTelInputComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @ViewChild('input', { static: true }) input: ElementRef;
  iti: any;

  constructor() { }

  ngOnInit() {
    this.iti = intlTelInput(this.input.nativeElement, {});
    this.onCountryChange();
  }

  onCountryChange() {
    const data = this.iti.getSelectedCountryData();
    this.parentForm.patchValue({ dialCode: data.dialCode });

    switch (data.iso2) {
      case 'us':
        $(this.input.nativeElement).mask('(000) 000-0000');
        break;

      default:
        $(this.input.nativeElement).unmask();
        break;
    }
  }
}
