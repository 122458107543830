import { createAction, props } from '@ngrx/store';

export const activateTV = createAction(
  '[Activate TV] Activate TV',
  props<{ email: string; code: string }>()
);

export const activateTVSuccess = createAction(
  '[Activate TV] Activate TV Success',
  props<{ activated: boolean; message: string }>()
);

export const activateTVFailure = createAction(
  '[Activate TV] Activate TV Failure',
  props<{ error: any }>()
);
