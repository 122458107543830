import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/change-subscription-plan/change-subscription-plan.actions';
import * as fromCore from '../../../../../core/store';
import { SubscriptionsService } from '../../../../../api/services';

@Injectable()
export class ChangeSubscriptionPlanEffects {
  changeSubscriptionPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeSubscriptionPlan),
      mergeMap(({ plan }) =>
        this.subscriptionsService.changePlan(plan).pipe(
          map(({ message }) =>
            fromActions.changeSubscriptionPlanSuccess({ message })
          ),
          catchError(error =>
            of(fromActions.changeSubscriptionPlanFailure({ error }))
          )
        )
      )
    )
  );

  changeSubscriptionPlanSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeSubscriptionPlanSuccess),
      map(() => fromCore.loadUser())
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
