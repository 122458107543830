import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import { fadeAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class LockedComponent implements OnInit {
  locked$: Observable<boolean>;

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.locked$ = this.store.pipe(select(fromStore.selectUserLocked));
  }
}
