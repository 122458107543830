import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import { fadeAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-continue',
  templateUrl: './continue.component.html',
  styleUrls: ['./continue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class ContinueComponent implements OnInit {
  continue$: Observable<any>;
  closed = false;
  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.continue$ = this.store.pipe(
      select(fromStore.selectVideos('Continue')),
      filter(videos => videos && videos.length > 0),
      map(videos => videos[0])
    );
  }

  dismiss(id) {
    this.closed = true;
    const data = { id, dismissed_notification: true };
    this.store.dispatch(fromStore.saveVideoUserData({ data, tag: 'Quiet' }));
  }
}
