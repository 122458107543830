import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectSubscribeState = createSelector(
  fromFeature.selectUserState,
  state => state.subscribe
);

export const selectSubscribeLoaded = createSelector(
  selectSubscribeState,
  state => state.loaded
);

export const selectSubscribeLoading = createSelector(
  selectSubscribeState,
  state => state.loading
);

export const selectSubscribeError = createSelector(
  selectSubscribeState,
  state => state.error
);

export const selectSubscribeEmail = createSelector(
  selectSubscribeState,
  state => state.email
);
