import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { StripeService } from '../services/stripe/stripe.service';

@Injectable({
  providedIn: 'root'
})
export class StripeGuard implements CanActivate {
  constructor(private stripe: StripeService) {}

  canActivate(): Observable<boolean> {
    return this.stripe.loaded$.pipe(
      tap(loaded => {
        if (!loaded) {
          this.stripe.init();
        }
      }),
      filter(loaded => loaded)
    );
  }
}
