import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectChangeSubscriptionPaymentState = createSelector(
  fromFeature.selectUserState,
  state => state.changePayment
);

export const selectChangeSubscriptionPaymentLoaded = createSelector(
  selectChangeSubscriptionPaymentState,
  state => state.loaded
);

export const selectChangeSubscriptionPaymentLoading = createSelector(
  selectChangeSubscriptionPaymentState,
  state => state.loading
);

export const selectChangeSubscriptionPaymentError = createSelector(
  selectChangeSubscriptionPaymentState,
  state => state.error
);

export const selectChangeSubscriptionPaymentMessage = createSelector(
  selectChangeSubscriptionPaymentState,
  state => state.message
);
