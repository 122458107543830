import { createAction, props } from '@ngrx/store';

export const deletePendingSubscription = createAction(
  '[Pending Subscription] Delete',
  props<{ email: string }>()
);

export const deletePendingSubscriptionSuccess = createAction(
  '[Pending Subscription] Delete Success',
  props<{ message: string }>()
);

export const deletePendingSubscriptionFailure = createAction(
  '[Pending Subscription] Delete Failure',
  props<{ error: any }>()
);
