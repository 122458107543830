import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, filter, switchMap, mergeMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class UserErrorGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(fromStore.selectUserLoadAttempted),
      filter(loadAttempted => loadAttempted),
      switchMap(() =>
        this.store.pipe(
          select(fromStore.selectUserError),
          map(error => !error || this.router.createUrlTree(['/'], { queryParams: { m: 'auth' }}))
        )
      )
    );
  }
}
