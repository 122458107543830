import { createAction, props } from '@ngrx/store';

export const verifyMobile = createAction(
  '[Verify Mobile] Verify Mobile',
  props<{ code: string }>()
);

export const verifyMobileSuccess = createAction(
  '[Verify Mobile] Verify Mobile Success'
);

export const verifyMobileFailure = createAction(
  '[Verify Mobile] Verify Mobile Failure',
  props<{ error: any }>()
);
