import { Injectable } from '@angular/core';

import { ScriptService } from 'ngx-script-loader';

import { BehaviorSubject } from 'rxjs';

declare const jwplayer: any;

@Injectable({
  providedIn: 'root'
})
export class JwplayerService {
  jwplayer: any;
  loaded$ = new BehaviorSubject(false);
  constructor(private scriptService: ScriptService) {}

  // Deprecated: jw script included in head tag
  init(libraryUrl: string) {
    this.scriptService.loadScript(libraryUrl).subscribe(() => {
      this.loaded$.next(true);
    });
  }

  getPlayer(element: any) {
    return jwplayer(element);
  }
}
