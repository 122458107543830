import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/loading/loading.actions';

export interface State {
  count: number;
}

export const initialState: State = {
  count: 0
};

const loadingReducer = createReducer(
  initialState,
  on(fromActions.increaseLoadingCount, state => ({
    ...state,
    count: state.count + 1
  })),
  on(fromActions.decreaseLoadingCount, state => ({
    ...state,
    count: state.count - 1
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return loadingReducer(state, action);
}
