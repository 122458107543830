import { createAction, props } from '@ngrx/store';

export const normalizeVideos = createAction(
  '[Videos] Normalize Videos',
  props<{ videos: any[] }>()
);

export const loadVideo = createAction(
  '[Videos] Load Video',
  props<{ id: string }>()
);

export const loadVideoSuccess = createAction(
  '[Videos] Load Video Success',
  props<{ video: any }>()
);

export const loadVideoFailure = createAction(
  '[Videos] Load Video Failure',
  props<{ id: string; error: any }>()
);

export const loadVideos = createAction(
  '[Videos] Load Videos',
  props<{ tag: string }>()
);

export const loadVideosSuccess = createAction(
  '[Videos] Load Videos Success',
  props<{ tag: string; videos: any[] }>()
);

export const loadVideosFailure = createAction(
  '[Videos] Load Videos Failure',
  props<{ tag: string; error: any }>()
);

export const favoriteVideo = createAction(
  '[Videos] Favorite Video',
  props<{ video: any; favorite: boolean }>()
);

export const favoriteVideoSuccess = createAction(
  '[Videos] Favorite Video Success',
  props<{ video: any; favorite: boolean }>()
);
