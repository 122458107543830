import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'odm-device-install',
  templateUrl: './device-install.component.html',
  styleUrls: ['./device-install.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceInstallComponent implements OnInit {
  @Input() loaded: boolean;
  @Input() loading: boolean;
  @Input() error: any;
  @Output() sendText = new EventEmitter<string>();

  phoneForm = this.fb.group({
    dialCode: [''],
    phone: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit() { }

  onSubmit() {
    const { dialCode, phone } = this.phoneForm.value;
    this.sendText.emit('+' + dialCode + phone);
  }
}
