import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/security/security.actions';
import { SubscriptionsService } from '../../../../../api/services';

@Injectable()
export class SecurityEffects {
  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.securitySubmitEmail),
      mergeMap(({ email }) =>
        this.subscriptionsService.verifyEmail(email).pipe(
          map(({ message }) =>
            fromActions.securitySubmitEmailSuccess({ message })
          ),
          catchError((error) =>
            of(fromActions.securitySubmitEmailFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) { }
}
