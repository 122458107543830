import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/user/user.actions';
import * as fromDevice from '../../actions/device/device.actions';
import { UsersService } from '../../../../api/services';
import { unwrap } from '../../../../util/code.util';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUser),
      mergeMap(() =>
        this.usersService.view().pipe(
          map(user => fromActions.loadUserSuccess({ user })),
          catchError(error => of(fromActions.loadUserFailure({ error })))
        )
      )
    )
  );

  loadUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUserSuccess),
      map(({ user }) => {
        if (user.has_active_subscription) {
          return fromDevice.authenticateDeviceSuccess({});
        } else {
          return fromDevice.deauthenticateDevice();
        }
      })
    )
  );

  editUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editUser),
      mergeMap(({ user }) =>
        this.usersService.edit(user).pipe(
          map(({ message }) => fromActions.editUserSuccess({ user, message })),
          catchError(error => of(fromActions.editUserFailure({ error })))
        )
      )
    )
  );

  changeUserEmailPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeUserEmailPreference),
      mergeMap(({ subscribed }) =>
        this.usersService.changeEmailPreference(!subscribed).pipe(
          map(resp =>
            fromActions.changeUserEmailPreferenceSuccess({
              emailPreference: !unwrap(
                resp,
                'UserEmailPreference',
                'unsubscribed'
              )
            })
          ),
          catchError(error =>
            of(fromActions.changeUserEmailPreferenceFailure({ error }))
          )
        )
      )
    )
  );

  loadUserAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUserAuth),
      mergeMap(() =>
        this.usersService.view().pipe(
          map(user => fromActions.loadUserSuccess({ user })),
          catchError(() => of(fromActions.loadUserAuthFailure()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private usersService: UsersService) { }
}
