import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectAccountState = createSelector(
  fromFeature.selectCoreState,
  state => state.account
);

export const selectAccount = createSelector(
  selectAccountState,
  state => state.account
);

export const selectAccountLoaded = createSelector(
  selectAccountState,
  state => state.loaded
);

export const selectAccountLoading = createSelector(
  selectAccountState,
  state => state.loading
);

export const selectAccountError = createSelector(
  selectAccountState,
  state => state.error
);

export const selectAccountSecurityMode = createSelector(
  selectAccountState,
  state => state.account.security_mode
);
