import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/activate-tv/activate-tv.actions';
import { DevicesService } from '../../../../../api/services';

@Injectable()
export class ActivateTVEffects {
  activateTV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateTV),
      mergeMap(({ email, code }) =>
        this.devicesService.authTv(code, email).pipe(
          map(({ activated, message }) => {
            return fromActions.activateTVSuccess({ activated, message });
          }),
          catchError(error => of(fromActions.activateTVFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private devicesService: DevicesService
  ) {}
}
