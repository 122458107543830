import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromCore from '../../store';
import * as fromRoot from '../../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';
import { KeyboardService } from '../../services/keyboard/keyboard.service';
import { channelNewVideoCount } from 'src/app/util/code.util';
import { CustomTextService } from '../../services/custom-text/custom-text.service';
import { ResourcesService } from '../../../api/services/resources/resources.service';

@Component({
  selector: 'odm-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class ChannelComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.atTop === true) {
      this.positionHeader();
    }
  }

  @ViewChild('modalHeader', { static: false }) modalHeader: ElementRef;

  destroy$ = new Subject<boolean>();
  channel$: Observable<any>;
  authenticated$: Observable<boolean>;
  resources$: Observable<any[]>;
  atTop = false;
  superHeaderWidth: number;
  superHeaderXPos: number;
  filterTag: RegExp;

  constructor(
    private router: Router,
    private store: Store<any>,
    private keyboard: KeyboardService,
    public customText: CustomTextService
  ) {}

  ngOnInit() {
    this.channel$ = this.store.pipe(select(fromCore.selectActiveChannel));
    this.authenticated$ = this.store.pipe(
      select(fromCore.selectDeviceAuthenticated)
    );
    this.resources$ = this.store.pipe(
      select(fromCore.selectActiveChannelResources)
    );
    const esc$ = this.keyboard.esc$.pipe(
      withLatestFrom(this.store.pipe(select(fromRoot.selectVideoId))),
      filter(([esc, videoId]) => !videoId),
      takeUntil(this.destroy$)
    );
    esc$.subscribe(() => {
      this.router.navigate(['', { outlets: { channel: null } }]);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onScroll() {
    this.atTop = this.modalHeader.nativeElement.getBoundingClientRect().top < 0;
    this.positionHeader();
  }

  openShareModal(id) {
    const options = { id, type: this.customText.channelTerm };
    this.store.dispatch(fromCore.openModal({ key: 'share', options }));
  }

  onFilter(tag: string) {
    this.filterTag = new RegExp(tag, 'i');
  }

  getFilteredVideos(videos: any[]) {
    if (this.filterTag) {
      return videos.filter((video) => this.filterTag.test(video.tags));
    }
    return videos;
  }

  getNewCount(channel): number {
    return channelNewVideoCount(channel);
  }

  getChannelImage(url?: string): string {
    if (url && url.endsWith('series-temp-thumb.png')) {
      return 'assets/img/channel-missing.png';
    }
    return url;
  }

  private positionHeader() {
    // Affix modal "super" header to top of screen on scroll
    this.superHeaderWidth = this.modalHeader.nativeElement.getBoundingClientRect().width;
    this.superHeaderXPos = this.modalHeader.nativeElement.getBoundingClientRect().left;
  }
}
