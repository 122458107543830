import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextCommunicationsService {
  constructor(private http: HttpClient) { }

  sendAppLink(phone: string, token: string) {
    const body = { phone, token };
    return this.http.post<any>(
      `${environment.apiUrl}/api/2.0/text_communications/send_app_link.json`,
      body
    );
  }
}
