import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'odm-icon-blocks',
  templateUrl: './icon-blocks.component.html',
  styleUrls: ['./icon-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBlocksComponent implements OnInit {
  @Input() icons: any[];
  @Input() narrow = false;
  constructor() {}

  ngOnInit() {}

  getColClass() {
    let colClass = [];

    if (this.narrow === true) {
      //colClass.push('is-narrow col-6 col-xl-3');
      colClass.push('is-narrow col-6');
    } else {
      colClass.push('col-12');
    }

    const colCount = this.icons.length;
    
    // 'Medium' window sizes
    if (colCount % 2 === 0) {
      colClass.push('col-md-6');
    } else {
      colClass.push('col-md-4');
    }

    // 'X-Large' screen sizes auto-fill in single row
    colClass.push('col-xl');

    return colClass;
  }

  getSvgSrc(identifier: string): string {
    return `assets/icons/material-design-icons/${identifier}/outline.svg`;
  }
}
