import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  AfterContentInit,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { CarouselItemDirective } from '../../directives';
import { unwrap } from '../../../util/code.util';

@Component({
  selector: 'odm-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit, AfterContentInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeDetectorRef.markForCheck();
  }
  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;
  active = 0;

  constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterContentInit() {}

  get stepSize(): number {
    const element = unwrap(this.items, 'first', 'element', 'nativeElement');
    if (element) {
      return parseInt(getComputedStyle(element).flexBasis);
    }
    return 25;
  }

  get itemsPerPage(): number {
    return 100 / this.stepSize;
  }

  get slideTransformation() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `translateX(${-this.active * this.stepSize}%)`
    );
  }

  get canMoveLeft(): boolean {
    return this.active > 0 && this.items.length > this.itemsPerPage;
  }

  get canMoveRight(): boolean {
    return this.active < this.items.length - this.itemsPerPage;
  }

  moveLeft() {
    if (this.canMoveLeft) {
      this.active -= 1;
    }
  }

  moveRight() {
    if (this.canMoveRight) {
      this.active += 1;
    }
  }
}
