import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideosService {
  constructor(private http: HttpClient) {}

  view(id: string) {
    return this.http.get<any>(
      `${environment.apiUrl2}/videos/${id}.json`
    );
  }

  // list(type: string) {
  //   return this.http.get<{ Videos: any[] }>(
  //     `${environment.apiUrl}/api/2.0/videos/index/${type}.json`
  //   );
  // }

  // recentlyWatched() {
  //   return this.http.get<{ Videos: any[] }>(
  //     `${environment.apiUrl}/api/2.0/video_user_data/recently_watched.json`
  //   );
  // }

  // lastWatched() {
  //   return this.http
  //     .get<{ Videos: any[] }>(
  //       `${environment.apiUrl}/api/2.0/video_user_data/last_watched.json`
  //     )
  //     .pipe(
  //       map(resp => {
  //         const video = resp['Video'];
  //         video['Video']['Channel'] = resp['Channel']['Channel'];
  //         return video;
  //       })
  //     );
  // }

  // recentlyAdded() {
  //   return this.http.get<{ Videos: any[] }>(
  //     `${environment.apiUrl}/api/2.0/videos/recently_added.json`
  //   );
  // }
}
