import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/activate-subscription/activate-subscription.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: undefined
};

const activateSubscriptionReducer = createReducer(
  initialState,
  on(fromActions.activateSubscription, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.activateSubscriptionSuccess, state => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.activateSubscriptionFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return activateSubscriptionReducer(state, action);
}
