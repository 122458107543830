import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  constructor(private http: HttpClient) {}

  view(tag: string) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/2.0/marketing_pages/view/${tag}.json`
    );
  }
}
