import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/coupon/coupon.actions';
import { SubscriptionsService } from '../../../../../api/services';

@Injectable()
export class CouponEffects {
  checkCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkCoupon),
      mergeMap(({ code, planId }) =>
        this.subscriptionsService.checkCoupon(code, planId).pipe(
          map(resp => {
            const id = resp['coupon_code_id'];
            const message = resp['message'];
            const data = {
              coupon_code_id: resp['coupon_code_id'],
              subscription_plan_id: resp['subscription_plan_id'],
              description: resp['description'],
              new_amount: resp['new_amount'],
              no_cc_required: resp['no_cc_required'],
              duration: resp['duration'],
              expiration: resp['expiration'],
              adjusts_trial_length: resp['adjusts_trial_length'],
              trial_length: resp['trial_length'],
              trial_end: resp['trial_end']
            };
            return fromActions.checkCouponSuccess({ id, message, data });
          }),
          catchError(error => of(fromActions.checkCouponFailure({ error })))
        )
      )
    )
  );

  loadCouponCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCouponCount),
      mergeMap(() =>
        this.subscriptionsService.countCoupons().pipe(
          map(count => fromActions.loadCouponCountSuccess({ count })),
          catchError(error => of(fromActions.loadCouponCountFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
