import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/verify-email/verify-email.actions';
import * as fromUser from '../../../../../core/store/actions/user/user.actions';
import { UsersService } from '../../../../../api/services';

@Injectable()
export class VerifyEmailEffects {
  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyEmail),
      mergeMap(({ code }) =>
        this.usersService.verifyEmail(code).pipe(
          map((resp) =>
            fromActions.verifyEmailSuccess()
          ),
          catchError((error) =>
            of(fromActions.verifyEmailFailure({ error }))
          )
        )
      )
    )
  );

  verifyEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyEmailSuccess),
      map(() => fromUser.loadUser())
    )
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService
  ) { }
}
