import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/change-subscription-payment/change-subscription-payment.actions';
import * as fromCore from '../../../../../core/store';
import { SubscriptionsService } from '../../../../../api/services';

@Injectable()
export class ChangeSubscriptionPaymentEffects {
  changeSubscriptionPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeSubscriptionPayment),
      mergeMap(({ payment }) =>
        this.subscriptionsService.changePayment(payment).pipe(
          map(({ message }) =>
            fromActions.changeSubscriptionPaymentSuccess({ message })
          ),
          catchError(error =>
            of(fromActions.changeSubscriptionPaymentFailure({ error }))
          )
        )
      )
    )
  );

  changeSubscriptionPaymentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeSubscriptionPaymentSuccess),
      map(() => fromCore.loadUser())
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService
  ) {}
}
