import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as fromActions from '../../actions/marketing-content/marketing-content.actions';
import * as fromChannels from '../../../../core/store/actions/channels/channels.actions';
import * as fromVideos from '../../../../core/store/actions/videos/videos.actions';
import { MarketingContentsService } from '../../../../api/services';

@Injectable()
export class MarketingContentEffects {
  loadMarketingContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMarketingContent),
      mergeMap(() =>
        this.marketingContentsService.view().pipe(
          map(content => fromActions.loadMarketingContentSuccess({ content })),
          catchError(error => of(fromActions.loadMarketingContentFailure({ error })))
        )
      )
    )
  );

  loadMarketingContentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMarketingContentSuccess),
      // map(({ tag, page }) => fromActions.normalizePage({ tag, page })),
      mergeMap(({ content }) => {
        const channels = [
          {
            ...content['preview_video_list'],
            title: content['preview_content_title']
          }
        ].filter(channel => channel && channel['id']);
        const videos = [
          content['introduction_featured_trailer']
        ].filter(video => video && video['id']);
        return of(
          fromChannels.normalizeChannels({ channels }),
          fromVideos.normalizeVideos({ videos })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private marketingContentsService: MarketingContentsService
  ) { }
}
