import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/resources/resources.actions';
import { resourceKey } from 'src/app/util/code.util';

export interface State {
  entities: { [key: string]: any };
  entityLoading: { [key: string]: boolean };
  entityError: { [key: string]: any };
}

export const initialState: State = {
  entities: {},
  entityLoading: {},
  entityError: {},
};

const resourcesReducer = createReducer(
  initialState,
  on(fromActions.loadResources, (state, { source, id }) => {
    const key = resourceKey(source, id);
    const entityLoading = {
      ...state.entityLoading,
      [key]: true,
    };
    const entityError = {
      ...state.entityError,
      [key]: undefined,
    };
    return {
      ...state,
      entityLoading,
      entityError,
    };
  }),
  on(fromActions.loadResourcesSuccess, (state, { key, resources }) => {
    const entities = { ...state.entities, [key]: resources };
    const entityLoading = {
      ...state.entityLoading,
      [key]: false,
    };
    const entityError = {
      ...state.entityError,
      [key]: undefined,
    };
    return {
      ...state,
      entities,
      entityLoading,
      entityError,
    };
  }),
  on(fromActions.loadResourcesFailure, (state, { key, error }) => {
    const entityLoading = {
      ...state.entityLoading,
      [key]: false,
    };
    const entityError = {
      ...state.entityError,
      [key]: error,
    };
    return {
      ...state,
      entityLoading,
      entityError,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return resourcesReducer(state, action);
}
