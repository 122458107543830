import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {
  constructor(private store: Store<any>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.selectAccountLoaded),
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.loadAccount());
        }
      }),
      filter(loaded => loaded)
    );
  }
}
