import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Store } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';
import { CustomTextService } from '../../services/custom-text/custom-text.service';

@Component({
  selector: 'odm-platforms-modal',
  templateUrl: './platforms-modal.component.html',
  styleUrls: ['./platforms-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class PlatformsModalComponent implements Modal, OnInit {
  id: number;
  constructor(
    private store: Store<any>,
    public customText: CustomTextService
  ) {}

  ngOnInit() {}

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  get origin() {
    return location.origin;
  }
}
