import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'odm-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteButtonComponent implements OnInit {
  @Input() favorite: boolean;
  @Input() loading: boolean;
  @Output() toggle = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  onClick(value) {
    this.toggle.emit(value);
  }
}
