import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { Observable, fromEvent } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';

@Component({
  selector: 'odm-install-prompt-bar',
  templateUrl: './install-prompt-bar.component.html',
  styleUrls: ['./install-prompt-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallPromptBarComponent implements OnInit {
  dismissed = false;
  deferredPrompt: any;
  account$: Observable<any>;
  constructor(
    private store: Store<any>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromStore.selectAccount));
    const beforeinstallprompt$ = fromEvent(window, 'beforeinstallprompt');
    beforeinstallprompt$.subscribe((e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.changeDetectorRef.markForCheck();
    });
  }

  prompt() {
    this.deferredPrompt.prompt();
    this.dismissed = true;
  }
}
