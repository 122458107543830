import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import * as fromUser from '../../../modules/user/store';
import { fadeInAnimation } from 'src/app/util/animation.util';
import { StripeService } from '../../services/stripe/stripe.service';
import { unwrap } from 'src/app/util/code.util';

@Component({
  selector: 'odm-change-subscription-payment-modal',
  templateUrl: './change-subscription-payment-modal.component.html',
  styleUrls: ['./change-subscription-payment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class ChangeSubscriptionPaymentModalComponent
  implements Modal, OnInit, AfterViewInit, OnDestroy {
  id: number;
  message$: Observable<string>;
  loading$: Observable<boolean>;
  error$: Observable<any>;
  @ViewChild('cardContainer', { static: true }) cardContainer: ElementRef;
  changePaymentForm = this.fb.group({
    cc_valid: [false, Validators.requiredTrue],
    stripe_source_token: []
  });
  card = this.stripe.card;
  stripeMessage: string;
  stripeLoading = false;
  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private stripe: StripeService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store.dispatch(fromUser.resetChangeSubscriptionPayment());
    this.message$ = this.store.pipe(
      select(fromUser.selectChangeSubscriptionPaymentMessage)
    );
    this.loading$ = this.store.pipe(
      select(fromUser.selectChangeSubscriptionPaymentLoading)
    );
    this.error$ = this.store.pipe(
      select(fromUser.selectChangeSubscriptionPaymentError)
    );
  }

  ngAfterViewInit() {
    this.card.mount(this.cardContainer.nativeElement);
    this.card.addEventListener('change', event => {
      this.stripeMessage = unwrap(event, 'error', 'message');
      this.changePaymentForm.patchValue({ cc_valid: event['complete'] });
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.stripe.unmount();
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  onSubmit() {
    this.createStripeToken();
  }

  createStripeToken() {
    this.stripeLoading = true;
    this.stripe.createToken().then(event => {
      if (event.error) {
        this.stripeMessage = unwrap(event, 'error', 'message');
      } else {
        this.changePaymentForm.patchValue({
          stripe_source_token: event.token.id
        });
        this.store.dispatch(
          fromUser.changeSubscriptionPayment({
            payment: this.changePaymentForm.value
          })
        );
      }
      this.stripeLoading = false;
      this.changeDetectorRef.markForCheck();
    });
  }
}
