import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import * as fromUser from '../../../modules/user/store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-change-subscription-plan-modal',
  templateUrl: './change-subscription-plan-modal.component.html',
  styleUrls: ['./change-subscription-plan-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class ChangeSubscriptionPlanModalComponent implements Modal, OnInit {
  id: number;
  user$: Observable<any>;
  plans$: Observable<any[]>;
  message$: Observable<string>;
  loading$: Observable<boolean>;
  error$: Observable<any>;
  changePlanForm = this.fb.group({
    subscription_plan_id: ['', Validators.required]
  });
  constructor(private store: Store<any>, private fb: FormBuilder) {}

  ngOnInit() {
    this.store.dispatch(fromUser.resetChangeSubscriptionPlan());
    this.user$ = this.store.pipe(select(fromStore.selectUser));
    this.plans$ = this.store.pipe(select(fromUser.selectSubscriptionPlans));
    this.message$ = this.store.pipe(
      select(fromUser.selectChangeSubscriptionPlanMessage)
    );
    this.loading$ = this.store.pipe(
      select(fromUser.selectChangeSubscriptionPlanLoading)
    );
    this.error$ = this.store.pipe(
      select(fromUser.selectChangeSubscriptionPlanError)
    );
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  onSubmit() {
    this.store.dispatch(
      fromUser.changeSubscriptionPlan({ plan: this.changePlanForm.value })
    );
  }
}
