import { createAction, props } from '@ngrx/store';

export const changeSubscriptionPayment = createAction(
  '[Change Subscription Payment] Change Subscription Payment',
  props<{ payment: any }>()
);

export const changeSubscriptionPaymentSuccess = createAction(
  '[Change Subscription Payment] Change Subscription Payment Success',
  props<{ message: string }>()
);

export const changeSubscriptionPaymentFailure = createAction(
  '[Change Subscription Payment] Change Subscription Payment Failure',
  props<{ error: any }>()
);

export const resetChangeSubscriptionPayment = createAction(
  '[Change Subscription Payment] Reset Change Subscription Payment'
);
