import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromCore from '../../../../core/store';
import * as fromRoot from '../../../../store';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { CustomTextService } from 'src/app/core/services/custom-text/custom-text.service';

@Component({
  selector: 'odm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  authenticated$: Observable<boolean>;
  account$: Observable<any>;
  content$: Observable<any>;
  textCommLoaded$: Observable<boolean>;
  textCommLoading$: Observable<boolean>;
  textCommError$: Observable<any>;
  // watched$: Observable<any[]>;
  // watchedLoaded$: Observable<boolean>;
  // watchedLoading$: Observable<boolean>;
  // added$: Observable<any[]>;
  // addedLoaded$: Observable<boolean>;
  // addedLoading$: Observable<boolean>;
  confirmationId = `Captcha-${Date.now()}`;
  phone: string;

  constructor(
    private store: Store<any>,
    private ga: GoogleAnalyticsService,
    public customText: CustomTextService
  ) { }

  ngOnInit() {
    this.authenticated$ = this.store.pipe(
      select(fromCore.selectDeviceAuthenticated)
    );
    this.account$ = this.store.pipe(select(fromCore.selectAccount));
    this.content$ = this.authenticated$.pipe(
      switchMap(authenticated => this.store.pipe(select(
        authenticated ? fromCore.selectSubscriberContent : fromCore.selectMarketingContent
      )))
    );
    this.textCommLoaded$ = this.store.pipe(
      select(fromStore.selectTextCommLoaded)
    );
    this.textCommLoading$ = this.store.pipe(
      select(fromStore.selectTextCommLoading)
    );
    this.textCommError$ = this.store.pipe(
      select(fromStore.selectTextCommError)
    );

  //   this.watched$ = this.store.pipe(select(fromCore.selectVideos('Watched')));
  //   this.watchedLoaded$ = this.store.pipe(
  //     select(fromCore.selectVideosLoaded('Watched'))
  //   );
  //   this.watchedLoading$ = this.store.pipe(
  //     select(fromCore.selectVideosLoading('Watched'))
  //   );

  //   this.added$ = this.store.pipe(select(fromCore.selectVideos('Added')));
  //   this.addedLoaded$ = this.store.pipe(
  //     select(fromCore.selectVideosLoaded('Added'))
  //   );
  //   this.addedLoading$ = this.store.pipe(
  //     select(fromCore.selectVideosLoading('Added'))
  //   );

  //   this.store.dispatch(fromCore.loadVideos({ tag: 'Watched' }));
  //   this.store.dispatch(fromCore.loadVideos({ tag: 'Added' }));

    const queryModal$ = this.store.pipe(
      select(fromRoot.selectQueryModal),
      filter((key) => !!key)
    );
    queryModal$.subscribe((key) => {
      this.store.dispatch(fromCore.openModal({ key }));
    });

    const confirmedCaptcha$ = this.store.pipe(
      select(fromCore.selectConfirmed(this.confirmationId)),
      filter(data => !!data)
    );
    confirmedCaptcha$.subscribe(token => {
      this.store.dispatch(fromStore.sendAppLink({ phone: this.phone, token }));
      this.ga.event('submit', { event_category: 'Download Link SMS' });
    })
  }

  onSendText(phone) {
    this.phone = phone;
    const options = { id: this.confirmationId };
    this.store.dispatch(fromCore.openModal({ key: 'captcha', options }));
  }

  openPlatformsModal() {
    this.store.dispatch(fromCore.openModal({ key: 'platforms' }));
  }
}
