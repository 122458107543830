import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

import { interval, Observable } from 'rxjs';

@Component({
  selector: 'odm-stopwatch',
  templateUrl: './stopwatch.component.html',
  styleUrls: ['./stopwatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopwatchComponent implements OnInit {
  @Input() start: number = 0;
  time$: Observable<number>;

  constructor() {}

  ngOnInit() {
    this.time$ = interval(1000);
  }

  formatClock(time: number) {
    const hours = time / 3600 >> 0;
    const minutes = time % 3600 / 60 >> 0;
    const seconds = time % 60;
    return (hours ? hours + ':' : '')
      + minutes.toString().padStart(2, '0') + ':'
      + seconds.toString().padStart(2, '0');
  }
}
