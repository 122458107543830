import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectStreamKeyState = createSelector(
  fromFeature.selectCoreState,
  state => state.streamKey
);

export const selectLiveStreamKey = createSelector(
  selectStreamKeyState,
  state => state.streamKey
);

export const selectLiveStreamKeyLoaded = createSelector(
  selectStreamKeyState,
  state => state.loaded
);

export const selectLiveStreamKeyLoading = createSelector(
  selectStreamKeyState,
  state => state.loading
);

export const selectLiveStreamKeyError = createSelector(
  selectStreamKeyState,
  state => state.error
);
