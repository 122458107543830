import { ActivateSubscriptionEffects } from './activate-subscription/activate-subscription.effects';
import { ActivateTVEffects } from './activate-tv/activate-tv.effects';
import { CancelSubscriptionEffects } from './cancel-subscription/cancel-subscription.effects';
import { ChangeSubscriptionPaymentEffects } from './change-subscription-payment/change-subscription-payment.effects';
import { ChangeSubscriptionPlanEffects } from './change-subscription-plan/change-subscription-plan.effects';
import { ContinueSubscriptionEffects } from './continue-subscription/continue-subscription.effects';
import { CouponEffects } from './coupon/coupon.effects';
import { SecurityEffects } from './security/security.effects';
import { SubscribeEffects } from './subscribe/subscribe.effects';
import { SubscriptionEligibilityEffects } from './subscription-eligibility/subscription-eligibility.effects';
import { SubscriptionPlansEffects } from './subscription-plans/subscription-plans.effects';
import { VerifyEmailEffects } from './verify-email/verify-email.effects';
import { VerifyMobileEffects } from './verify-mobile/verify-mobile.effects';

export const effects = [
  ActivateSubscriptionEffects,
  ActivateTVEffects,
  CancelSubscriptionEffects,
  ChangeSubscriptionPaymentEffects,
  ChangeSubscriptionPlanEffects,
  ContinueSubscriptionEffects,
  CouponEffects,
  SecurityEffects,
  SubscribeEffects,
  SubscriptionEligibilityEffects,
  SubscriptionPlansEffects,
  VerifyEmailEffects,
  VerifyMobileEffects
];
