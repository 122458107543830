export const unwrap = (object: any, ...path: string[]): any => {
  return path.reduce((acc, cur) => acc && acc[cur], object);
};

export const pluckIds = (object: any, path: string): any[] => {
  return (object && object[path] && object[path].map(item => item.id)) || [];
};

export const channelNewVideoCount = (channel: any): number => {
  return channel.videos.reduce((acc, cur) => {
    if (cur.data && cur.data['new']) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const channelLiveVideoCount = (channel: any): number => {
  return channel.videos.reduce((acc, cur) => {
    if (cur.data && cur['live']) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const resourceKey = (source: string, id: string) => `${source}-${id}`;
