import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Observable, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})
export class AuthModalComponent implements Modal, OnInit, OnDestroy {
  id: number;
  destroy$ = new Subject<boolean>();
  tokenLoaded$: Observable<boolean>;
  tokenLoading$: Observable<boolean>;
  tokenError$: Observable<any>;
  method$: Observable<string>;
  resendEmailLoading$: Observable<boolean>;
  resendEmailError$: Observable<any>;
  resendSubscriptionEmailLoading$: Observable<boolean>;
  resendSubscriptionEmailError$: Observable<any>;
  userLoading$: Observable<boolean>;
  userForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  constructor(private store: Store<any>, private fb: FormBuilder) {}

  ngOnInit() {
    this.tokenLoaded$ = this.store.pipe(
      select(fromStore.selectDeviceTokenLoaded)
    );
    this.tokenLoading$ = this.store.pipe(
      select(fromStore.selectDeviceTokenLoading)
    );
    this.tokenError$ = this.store.pipe(
      select(fromStore.selectDeviceTokenError)
    );
    this.method$ = this.store.pipe(
      select(fromStore.selectDeviceMethod)
    );
    this.resendEmailLoading$ = this.store.pipe(
      select(fromStore.selectDeviceAuthEmailLoading)
    );
    this.resendEmailError$ = this.store.pipe(
      select(fromStore.selectDeviceAuthEmailError)
    );
    this.resendSubscriptionEmailLoading$ = this.store.pipe(
      select(fromStore.selectSubscriptionEmailLoading)
    );
    this.resendSubscriptionEmailError$ = this.store.pipe(
      select(fromStore.selectSubscriptionEmailError)
    );
    this.userLoading$ = this.store.pipe(select(fromStore.selectUserLoading));
    const userLoaded$ = this.store.pipe(
      select(fromStore.selectUserLoaded),
      filter(loaded => loaded)
    );
    userLoaded$.subscribe(() => {
      location.reload();
    });
    const poll$ = this.tokenLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => timer(15000, 5000)),
      takeUntil(this.destroy$)
    )
    poll$.subscribe(() => {
      this.checkAuth();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  onSubmit() {
    const { email } = this.userForm.value;
    this.store.dispatch(fromStore.saveDevice({ email }));
  }

  resendEmail() {
    this.store.dispatch(fromStore.resendDeviceAuthEmail());
  }

  resendSubscriptionEmail() {
    const { email } = this.userForm.value;
    this.store.dispatch(fromStore.resendSubscriptionActivationEmail({ email }));
  }

  checkAuth() {
    this.store.dispatch(fromStore.loadUser());
  }
}
