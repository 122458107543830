import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromReducer from '../../reducers/video-user-data/video-user-data.reducer';

export const selectVideoUserDataState = createSelector(
  fromFeature.selectCoreState,
  state => state.videoUserData
);

const { selectEntities } = fromReducer.adapter.getSelectors();

export const selectVideoUserDataEntities = createSelector(
  selectVideoUserDataState,
  selectEntities
);

const selectVideoUserDataEntityLoading = createSelector(
  selectVideoUserDataState,
  state => state.entityLoading
);

const selectVideoUserDataEntityError = createSelector(
  selectVideoUserDataState,
  state => state.entityError
);

export const selectVideoUserData = id =>
  createSelector(selectVideoUserDataEntities, entities => entities[id]);

export const selectVideoUserDataLoaded = id =>
  createSelector(selectVideoUserDataEntities, entities => !!entities[id]);

export const selectVideoUserDataLoading = id =>
  createSelector(
    selectVideoUserDataEntityLoading,
    entityLoading => entityLoading[id]
  );

export const selectVideoUserDataError = id =>
  createSelector(
    selectVideoUserDataEntityError,
    entityError => entityError[id]
  );
