import { createAction, props } from '@ngrx/store';

export const saveDevice = createAction(
  '[Device] Save Device',
  props<{ email: string }>()
);

export const saveDeviceSuccess = createAction(
  '[Device] Save Device Success',
  props<{ token: string; activated?: boolean, method?: string }>()
);

export const saveDeviceFailure = createAction(
  '[Device] Save Device Failure',
  props<{ error: any }>()
);

export const authenticateDevice = createAction(
  '[Device] Authenticate Device',
  props<{ code: string; code2?: string, deviceOS?: string, web_auth?: boolean }>()
);

export const authenticateDeviceSuccess = createAction(
  '[Device] Authenticate Device Success',
  props<{ token?: string, message?: string, deviceOS?: string, type?: string }>()
);

export const authenticateDeviceFailure = createAction(
  '[Device] Authenticate Device Failure',
  props<{ error: any, deviceOS?: string }>()
);

export const deauthenticateDevice = createAction(
  '[Device] Deauthenticate Device'
);

export const deauthenticateDeviceSuccess = createAction(
  '[Device] Deauthenticate Device Success',
  props<{ message: string }>()
);

export const deauthenticateDeviceFailure = createAction(
  '[Device] Deauthenticate Device Failure',
  props<{ error: any }>()
);

export const resendDeviceAuthEmail = createAction(
  '[Device] Resend Device Auth Email'
);

export const resendDeviceAuthEmailSuccess = createAction(
  '[Device] Resend Device Auth Email Success'
);

export const resendDeviceAuthEmailFailure = createAction(
  '[Device] Resend Device Auth Email Failure',
  props<{ error: any }>()
);

export const editDevice = createAction('[Device] Edit Device');

export const editDeviceSuccess = createAction('[Device] Edit Device Success');

export const editDeviceFailure = createAction(
  '[Device] Edit Device Failure',
  props<{ error: any }>()
);
