import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../../actions/stream-key/stream-key.actions';

export interface State {
  streamKey: any;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  streamKey: undefined,
  loaded: false,
  loading: false,
  error: undefined
};

const streamKeyReducer = createReducer(
  initialState,
  on(fromActions.loadLiveStreamKey, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.loadLiveStreamKeySuccess, (state, { streamKey }) => ({
    ...state,
    streamKey,
    loaded: true,
    loading: false,
    error: undefined
  })),
  on(fromActions.loadLiveStreamKeyFailure, (state, { error }) => ({
    ...state,
    streamKey: undefined,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return streamKeyReducer(state, action);
}
