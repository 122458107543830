import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  constructor(private http: HttpClient) {}

  view() {
    if (window['OdeumAccount']) {
      return of(window['OdeumAccount']);
    }
    const params = {
      hostname: environment.hostname || window.location.hostname,
      pathname: encodeURIComponent(window.location.pathname)
    };
    return this.http.get<any>(
      `${environment.apiUrl2}/accounts/web.json`,
      { params }
    );
  }
}
