import { createAction, props } from '@ngrx/store';

export const startPollingLiveStreamKey = createAction('[Stream Key] Start Polling Live');

export const loadLiveStreamKey = createAction('[Stream Key] Load Live');

export const loadLiveStreamKeySuccess = createAction(
  '[Stream Key] Load Live Success',
  props<{ streamKey: any }>()
);

export const loadLiveStreamKeyFailure = createAction(
  '[Stream Key] Load Live Failure',
  props<{ error: any }>()
);
