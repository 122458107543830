import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectTextCommState = createSelector(
  fromFeature.selectHomeState,
  state => state.textComm
);

export const selectTextCommLoaded = createSelector(
  selectTextCommState,
  state => state.loaded
);

export const selectTextCommLoading = createSelector(
  selectTextCommState,
  state => state.loading
);

export const selectTextCommError = createSelector(
  selectTextCommState,
  state => state.error
);
