import { createAction, props } from '@ngrx/store';

export const openModal = createAction('[Modals] Open Modal', ({ key, options }) => {
  const id = Date.now();
  const modal = { id, key, options };
  return { modal };
});

export const closeModal = createAction(
  '[Modals] Close Modal',
  props<{ id: number }>()
);
