import { createAction, props } from '@ngrx/store';

export const showToast = createAction('[Toasts] Show Toast', ({ toast }) => {
  toast.id = Date.now();
  return { toast };
});

export const hideToast = createAction(
  '[Toasts] Hide Toast',
  props<{ id: number }>()
);
