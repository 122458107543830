import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-iubenda-modal',
  templateUrl: './iubenda-modal.component.html',
  styleUrls: ['./iubenda-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class IubendaModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  account$: Observable<any>;
  constructor(private store: Store<any>, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromStore.selectAccount));
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }

  get iframeSrc(): any {
    let iframeUrl = "https://www.iubenda.com/privacy-policy/28723254";
    if(this.options.type) {
      iframeUrl = iframeUrl  + "/" + this.options.type;
      console.log('options', this.options);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }

}
