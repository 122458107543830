import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';

import { Modal } from '../modal.interface';
import * as fromStore from '../../store';
import { fadeInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'odm-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class HelpModalComponent implements Modal, OnInit {
  id: number;
  options: any;
  account$: Observable<any>;
  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromStore.selectAccount));
  }

  close() {
    this.store.dispatch(fromStore.closeModal({ id: this.id }));
  }
}
