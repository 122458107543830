import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromPages from './pages';
import * as fromComponents from './components';
import { SharedModule } from '../../shared/shared.module';
import { featureKey, reducers, effects } from './store';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature(effects)
  ],
  declarations: [...fromPages.components, ...fromComponents.components],
})
export class HomeModule {}
