import { createAction, props } from '@ngrx/store';

export const normalizeVideoUserData = createAction(
  '[VideoUserData] Normalize Video User Data',
  props<{ data: any[] }>()
);

export const saveVideoUserData = createAction(
  '[VideoUserData] Save Video User Data',
  props<{ data: any; tag?: string; video?: any }>()
);

export const saveVideoUserDataSuccess = createAction(
  '[VideoUserData] Save Video User Data Success',
  props<{ data: any; tag?: string; video?: any }>()
);

export const saveVideoUserDataFailure = createAction(
  '[VideoUserData] Save Video User Data Failure',
  props<{ id: string; error: any }>()
);
