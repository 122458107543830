import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromActivateSubscription from './activate-subscription/activate-subscription.reducer';
import * as fromActivateTV from './activate-tv/activate-tv.reducer';
import * as fromCancelSubscription from './cancel-subscription/cancel-subscription.reducer';
import * as fromChangeSubscriptionPayment from './change-subscription-payment/change-subscription-payment.reducer';
import * as fromChangeSubscriptionPlan from './change-subscription-plan/change-subscription-plan.reducer';
import * as fromContinueSubscription from './continue-subscription/continue-subscription.reducer';
import * as fromCoupon from './coupon/coupon.reducer';
import * as fromSecurity from './security/security.reducer';
import * as fromSubscribe from './subscribe/subscribe.reducer';
import * as fromSubscriptionEligibility from './subscription-eligibility/subscription-eligibility.reducer';
import * as fromSubscriptionPlans from './subscription-plans/subscription-plans.reducer';
import * as fromVerifyEmail from './verify-email/verify-email.reducer';
import * as fromVerifyMobile from './verify-mobile/verify-mobile.reducer';

export const featureKey = 'user';

export interface State {
  activateSubscription: fromActivateSubscription.State;
  activateTV: fromActivateTV.State;
  cancelSubscription: fromCancelSubscription.State;
  changePayment: fromChangeSubscriptionPayment.State;
  changePlan: fromChangeSubscriptionPlan.State;
  continueSubscription: fromContinueSubscription.State;
  coupon: fromCoupon.State;
  security: fromSecurity.State;
  subscribe: fromSubscribe.State;
  subscriptionEligibility: fromSubscriptionEligibility.State;
  subscriptionPlans: fromSubscriptionPlans.State;
  verifyEmail: fromVerifyEmail.State;
  verifyMobile: fromVerifyMobile.State;
}

export const reducers: ActionReducerMap<State> = {
  activateSubscription: fromActivateSubscription.reducer,
  activateTV: fromActivateTV.reducer,
  cancelSubscription: fromCancelSubscription.reducer,
  changePayment: fromChangeSubscriptionPayment.reducer,
  changePlan: fromChangeSubscriptionPlan.reducer,
  continueSubscription: fromContinueSubscription.reducer,
  coupon: fromCoupon.reducer,
  security: fromSecurity.reducer,
  subscribe: fromSubscribe.reducer,
  subscriptionEligibility: fromSubscriptionEligibility.reducer,
  subscriptionPlans: fromSubscriptionPlans.reducer,
  verifyEmail: fromVerifyEmail.reducer,
  verifyMobile: fromVerifyMobile.reducer
};

export const selectUserState = createFeatureSelector<State>(featureKey);
