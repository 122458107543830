import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';
import { unwrap } from 'src/app/util/code.util';

@Injectable({
  providedIn: 'root'
})
export class LiveGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(fromStore.selectAccount),
      filter(account => account),
      map(account => {
        const queryParams = route.queryParams;
        const live = unwrap(account, 'web_live');
        return live || this.router.createUrlTree(['/coming-soon'], { queryParams });
      })
    );
  }
}
