import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriberContentsService {
  constructor(private http: HttpClient) {}

  view() {
    return this.http.get<any>(
      `${environment.apiUrl2}/subscriber-contents/view.json`
    );
  }
}
