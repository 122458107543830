import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';

export const selectCouponState = createSelector(
  fromFeature.selectUserState,
  state => state.coupon
);

export const selectCouponId = createSelector(
  selectCouponState,
  state => state.id
);

export const selectCouponLoading = createSelector(
  selectCouponState,
  state => state.loading
);

export const selectCouponMessage = createSelector(
  selectCouponState,
  state => state.message
);

export const selectCouponData = createSelector(
  selectCouponState,
  state => state.data
);

export const selectCouponError = createSelector(
  selectCouponState,
  state => state.error
);

export const selectCouponCount = createSelector(
  selectCouponState,
  state => state.count
);

export const selectCouponCountLoaded = createSelector(
  selectCouponState,
  state => state.countLoaded
);

export const selectCouponCountLoading = createSelector(
  selectCouponState,
  state => state.countLoading
);

export const selectCouponCountError = createSelector(
  selectCouponState,
  state => state.countError
);
